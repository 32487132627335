import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LayerDataPopup from '../mapComponents/LayerDataPopup.js';
import GeolocationLayerPopup from '../mapComponents/GeolocationLayerPopup.js';
import ModelLayerPopup from '../mapComponents/ModelLayerPopup.js';
import EventLayerPopup from '../mapComponents/EventLayerPopup.js';
import { eriScenarios } from '../constants/eri.js';
import { createRoot } from 'react-dom/client';
import IncidentReportLayerPopup from '../mapComponents/IncidentReportLayerPopup.js';
import TaskManagementMapLayerPopup from 'components/TaskManagement/TaskManagementMapLayerPopup.js';
import CommonLayerPopup from '../mapComponents/CommonLayerPopup.js';
import Popup from '../mapComponents/Popup.js';
import PowerOutageLayerPopup from '../mapComponents/PowerOutageLayerPopup.js';

const eventSourceIDs = [
  'polygon_events_source_id',
  'point_events_source_id',
  'custom_polygon_events_source_id',
  'custom_point_events_source_id',
  'public.hurricane_cones_source_id',
  'public.hurricane_points_source_id',
  'public.hurricane_paths_source_id',
  'polygon_poweroutage_source_id',
  'public.polygon_poweroutage_source_layer',
  'earthquakes_source_layer',
]

function filterEarthquakeDuplicates(arr) {
  const seenIds = new Set();

  return arr.filter(item => {
    if (item.source === 'earthquakes_source_layer') {
      const id = item?.properties?.id;
      if (id && !seenIds.has(id)) {
        seenIds.add(id);
        return true;
      }
      return false;
    }
    return true; // Keep other objects that are not from 'earthquakes_source_layer'
  });
}

const useMapLayout = (params) => {
  const {
    mapboxgl,
    map,
    mapSettings,
    owmToken,
    layers,
    clickedLayers,
    ethelData,
    sources,
    setLayers,
    updateLayer,
    setSelectedGeolocation,
    setBottomPanelActive,
    eventSelected,
    reportSelected,
    taskSelected,
    sidebarSecondaryHandler,
    setSidebarActiveItem,
    setSelectedEventTabNews,
    setClickedLayers,
    setSources,
    handleCustomEventSelected,
    eventLayerState
  } = params;

  // constant variables
  const popupPlaceholder = document.createElement('div');
  const popupRoot = createRoot(popupPlaceholder);
  const eriScenario = mapSettings?.eriScenario;

  // State Declarations
  const [sidebarSelection, setSidebarSelection] = useState(false);
  const [geolocationsItemExpand, setGeolocationsItemExpand] = useState({
    actions: false,
    aors: false,
    plan_events: false,
    resources: false,
    work_assignments: false,
  });
  const [selectedModelFeature, setSelectedModelFeature] = useState();
  const [addingLayerToMap, setAddingLayerToMap] = useState(false);
  const [iconWasAddedToMap, setIconWasAddedToMap] = useState(false);
  const [iconForMap, setIconForMap] = useState();

  // useSelector varaibles
  const reduxActions = useSelector((state) => state.app.actions);
  const reduxCoreComponents = useSelector((state) => {
    return state.app.coreComponents;
  });
  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });
  const incidentReportData = useSelector(
    (state) =>
      state.app.allInitialIncidentReport &&
      state.app.allInitialIncidentReport.map((item) => {
        return {
          ...item,
          incident_type: item.incident_types
            ? item.incident_types.join(', ')
            : item.incidentType,
          alreadyEscalated: item.current_status.includes('Escalated'),
        };
      })
  );
  const groupResources = useSelector((state) => state.app.groupResources);
  const geolocationsItemExpandHandler = (item, value) => {
    setGeolocationsItemExpand({ ...geolocationsItemExpand, [item]: value });
  };
  const [sidebarItemExpand, setSidebarItemExpand] = useState({
    alerts: false,
    basemap: false,
    events: false,
    active_event_types: false,
    weather: false,
    geolocations: false,
  });

  // functions.
  function geolocationSelected(geolocation, type) {
    if (type === 'Action') {
      geolocationsItemExpandHandler(
        'actions',
        !geolocationsItemExpand['actions']
      );
    } else if (type === 'AOR') {
      geolocationsItemExpandHandler('aors', !geolocationsItemExpand['aors']);
    } else if (type === 'Resource') {
      geolocationsItemExpandHandler(
        'resources',
        !geolocationsItemExpand['resources']
      );
    } else if (type === 'Work Assignment') {
      geolocationsItemExpandHandler(
        'work_assignments',
        !geolocationsItemExpand['work_assignments']
      );
    }
    setSelectedGeolocation({ type, geolocation });
  }

  const sidebarItemExpandHandler = (item, value) => {
    setSidebarItemExpand({ ...sidebarItemExpand, [item]: value });
  };

  // useEffect
  useEffect(() => {
    if (clickedLayers?.length > 0) {
      const popup = new mapboxgl.Popup({ closeOnClick: false });
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }
      const firstLayerData = clickedLayers[0].data;

      popupRoot.render(
        <CommonLayerPopup>
          {clickedLayers.map((clickedLayer, index) => {
            const layerClicked = clickedLayer.data;
            const layer = layers.find((l) => l.id === layerClicked.layer.id);

            if (!!layerClicked.layer.metadata.usermade) {
              return (
                <LayerDataPopup
                  key={'data-popup-' + index}
                  layer={layer}
                  layers={layers}
                  setLayers={setLayers}
                  updateLayer={updateLayer}
                  clickPopup={popup}
                  e={layerClicked.e}
                  feature={layerClicked.features[0]}
                />
              );
            } else if (!!layerClicked.layer.metadata.geolocation) {
              setSelectedModelFeature(layerClicked.features[0]);
              return (
                <GeolocationLayerPopup
                  key={'geolocation-popup-' + index}
                  layer={layerClicked.layer}
                  clickPopup={popup}
                  e={layerClicked.e}
                  features={layerClicked.features}
                  actions={reduxActions}
                  AORs={reduxCoreComponents.CCs['Areas of Responsibility']}
                  resources={groupResources}
                  setSelectedGeolocation={(geolocation, type) =>
                    geolocationSelected(geolocation, type)
                  }
                  setSidebarSelection={setSidebarSelection}
                  sidebarItemExpandHandler={() =>
                    sidebarItemExpandHandler('geolocations', true)
                  }
                />
              );
            } else if (!!layerClicked.layer.metadata.incident_report) {
              setSelectedModelFeature(layerClicked.features[0]);
              return (
                <IncidentReportLayerPopup
                  key={'incident-popup-' + index}
                  layer={layerClicked.layer}
                  e={layerClicked.e}
                  features={layerClicked.features}
                  incidentReports={incidentReportData}
                  categoryItems={reduxNestedCategoryItems}
                  handleReportSelected={(report) => {
                    reportSelected(report);
                    setSidebarActiveItem('Incident Report');
                  }}
                />
              );
            } else if (!!layerClicked.layer.metadata.taskLayer) {
              setSelectedModelFeature(layerClicked.features);
              return (
                <TaskManagementMapLayerPopup
                  key={'task-management-popup-' + index}
                  features={layerClicked.features}
                  handleReportSelected={(report) => {
                    taskSelected(report);
                    setSidebarActiveItem('Task Management');
                  }}
                />
              );
            } else if (!!layerClicked.layer.metadata.model_layer) {
              setSelectedModelFeature(layerClicked.features[0]);
              setBottomPanelActive({
                layer: layerClicked.layer,
                feature: layerClicked.features[0],
                isERI: true,
              });
              return (
                <ModelLayerPopup
                  key={'model-layer-popup-' + index}
                  layer={layerClicked.layer}
                  clickPopup={popup}
                  e={layerClicked.e}
                  feature={layerClicked.features[0]}
                  setBottomPanelActive={setBottomPanelActive}
                  eriScenario={eriScenario}
                  eriScenarios={eriScenarios}
                  selectedDatetime={mapSettings?.selectedDatetime}
                />
              );
            } else if (!!layerClicked.layer.metadata.poc_layer) {
              setSelectedModelFeature(layerClicked.features[0]);
              setBottomPanelActive({
                layer: layerClicked.layer,
                feature: layerClicked.features[0],
                isPowerOutage: true,
              });
              return (
                <PowerOutageLayerPopup
                  key={'model-power-outage-popup-' + index}
                  layer={layerClicked.layer}
                  clickPopup={popup}
                  e={layerClicked.e}
                  feature={layerClicked.features[0]}
                  selectedDatetime={mapSettings?.selectedDatetime}
                />
              );
            } else if (!!layerClicked.layer.metadata.eventlayer) {
              const mapCurrent = map.current
              const point = layerClicked.e.point
              const features = filterEarthquakeDuplicates(mapCurrent.queryRenderedFeatures(point)
                .filter(f=>eventSourceIDs.includes(f.source)))
              console.log("features",features)
              return (
                <EventLayerPopup
                  key={'event-layer-popup-' + index}
                  layer={layerClicked.layer}
                  clickPopup={popup}
                  e={layerClicked.e}
                  features={features}
                  map={map}
                  events={eventLayerState.ethelData}
                  handleEventSelected={(event) => {
                    eventSelected(event);
                    sidebarSecondaryHandler(true, 'Selected Event');
                    setSidebarActiveItem('Events');
                    setSelectedEventTabNews();
                  }}
                  handleCustomEventSelected={handleCustomEventSelected} 
                />
              );
            } else if (!!layerClicked.layer.metadata.owmLayer) {
              setBottomPanelActive({
                layer: mapSettings?.weatherLayer,
                weatherData: layerClicked.e,
                isWeather: true,
              });
              return (
                <Popup
                  key={'data-popup-owm' + index}
                  weatherLayer={mapSettings?.weatherLayer}
                  weatherData={layerClicked.e}
                  owmToken={owmToken}
                  selectedDatetime={mapSettings?.selectedDatetime}
                />
              );
            }
          })}
        </CommonLayerPopup>
      );
      popup
        .setLngLat(firstLayerData.e.lngLat)
        .setDOMContent(popupPlaceholder)
        .addTo(map.current);
      setClickedLayers([]);
    }
  }, [clickedLayers, mapSettings?.selectedDatetime, mapSettings?.weatherLayer, eventLayerState.ethelData]);

  useEffect(() => {
    if (!!iconWasAddedToMap) {
      const usermadeLayers = map.current
        .getStyle()
        .layers.filter(
          (l) =>
            !!l.metadata &&
            !!l.metadata.usermade &&
            !layers.find((ll) => ll.id === l.id)
        );
      let newSources = [];
      let newLayers = [];
      usermadeLayers.forEach((l) => {
        const source = map.current.getSource(l.source);
        newSources = [...newSources, source];
        newLayers = [...newLayers, l];
      });
      setSources([...sources, ...newSources]);
      setLayers([...layers, ...newLayers]);
      setIconWasAddedToMap(false);
    }
  }, [iconWasAddedToMap]);

  return {
    sidebarSelection,
    selectedModelFeature,
  };
};

export default useMapLayout;
