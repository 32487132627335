import { eventTileHazards } from '../constants/eventHazards.js';
import {
  sampleDrawLayer,
  samplePointLayer,
  sampleLineLayer,
} from '../constants/sampleLayers';

const polygon_events_layer_id = 'polygon_events_layer_id';
const point_events_layer_id = 'point_events_layer_id';
const polygon_events_source_id = 'polygon_events_source_id';
const point_events_source_id = 'point_events_source_id';
const polygon_source_layer = 'public.polygon_events_layer';
const point_source_layer = 'public.point_events_layer';

const custom_polygon_events_layer_id = 'custom_polygon_events_layer_id';
const custom_point_events_layer_id = 'custom_point_events_layer_id';
const custom_polygon_events_source_id = 'custom_polygon_events_source_id';
const custom_point_events_source_id = 'custom_point_events_source_id';
const custom_polygon_source_layer = 'public.custom_polygon_events_layer';
const custom_point_source_layer = 'public.custom_point_events_layer';

const hurricane_cones_layer = 'public.hurricane_cones';
const hurricane_points_layer = 'public.hurricane_points';
const hurricane_paths_layer = 'public.hurricane_paths';
const hurricane_cones_layer_id = 'public.hurricane_cones_layer_id';
const hurricane_points_layer_id = 'public.hurricane_points_layer_id';
const hurricane_paths_layer_id = 'public.hurricane_paths_layer_id';
const hurricane_cones_source_id = 'public.hurricane_cones_source_id';
const hurricane_points_source_id = 'public.hurricane_points_source_id';
const hurricane_paths_source_id = 'public.hurricane_paths_source_id';

const earthquakes_source_layer = 'earthquakes_source_layer';
const earthquakes_clusters_layer_id = 'earthquakes_clusters_layer_id';
const earthquakes_cluster_count_layer_id = 'earthquakes_cluster_count_layer_id';
const earthquakes_unclustered_point_layer_id = 'earthquakes_unclustered_point_layer_id';

const polygon_poweroutage_layer_id = 'polygon_poweroutage_layer_id';
const polygon_poweroutage_source_id = 'polygon_poweroutage_source_id';
const polygon_poweroutage_source_layer = 'public.polygon_poweroutage_source_layer';

const eventTypesToMapboxColorExpression = () => {
  const expression = [
    'match',
    ['get', 'hazard_name'],
    ...eventTileHazards.map((t) => [t.hazard_name, t.hazard_color]).flat(),
    'Wild Fires',
    '#FF0000',
    '#FFFF00' /* other */,
  ];
  return expression;
};

function hurricaneLayerTypesToMapboxColorExpression() {
  return [
    'step',
    ['get', 'unified_speed'],
    '#0099FF',
    33,
    '#81C056',
    63,
    '#F6C445',
    82,
    '#EE7932',
    95,
    '#D64A27',
    112,
    '#AC32F6',
    136,
    '#D535A9',
  ];
}

export default function mapEventsEffect(
  map,
  mapHasLoaded,
  ethelData,
  eventsVisibleToggle,
  selectedDatetime,
  setLayerClicked,
  apiPrefix,
  earthquakesData,
  poweroutageData,
  poweroutageActive,
  eventToggles,
  reduxFeatureFlags,
  group_guid,
  forceRecreate = false
) {
  if (!mapHasLoaded || !earthquakesData) return;

  const layerExists = (layerId) => !!map.current.getStyle().layers.find(l => l.id === layerId);
  const sourceExists = (sourceId) => !!map.current.getSource(sourceId);

  // Only remove and recreate layers if they don't exist or if forceRecreate is true
  const shouldCreateLayers = forceRecreate || !layerExists('polygon_events_layer_id');

  if (shouldCreateLayers) {
    // Remove existing layers if they exist
    [
      polygon_events_layer_id,
      point_events_layer_id,
      custom_polygon_events_layer_id,
      custom_point_events_layer_id,
      polygon_poweroutage_layer_id,
      hurricane_cones_layer_id,
      hurricane_paths_layer_id,
      hurricane_points_layer_id,
      earthquakes_clusters_layer_id,
      earthquakes_cluster_count_layer_id,
      earthquakes_unclustered_point_layer_id
    ].forEach(layerId => {
      if (layerExists(layerId)) {
        map.current.removeLayer(layerId);
      }
    });

    // Remove existing sources if they exist
    [
      polygon_events_source_id,
      point_events_source_id,
      custom_polygon_events_source_id,
      custom_point_events_source_id,
      polygon_poweroutage_source_id,
      hurricane_cones_source_id,
      hurricane_paths_source_id,
      hurricane_points_source_id,
      earthquakes_source_layer
    ].forEach(sourceId => {
      if (sourceExists(sourceId)) {
        map.current.removeSource(sourceId);
      }
    });

    if (!!eventsVisibleToggle) {
      try {
        // NWS Alerts and Wildfires
        const polygonTiles = `${apiPrefix}/event_tiles/${polygon_source_layer}/{z}/{x}/{y}.pbf`;
        const formattedDatetime = selectedDatetime.utc().format('YYYY-MM-DD HH:mm:ss');
        const endDatetime = selectedDatetime.utc().format('YYYY-MM-DD HH:mm:ss');
        const hazardNameFilterList = "'Wild Fires'";

        const tilesPath = `${polygonTiles}?datetime_selected=${formattedDatetime}&end_datetime=${endDatetime}&hazard_name_filter_list=${hazardNameFilterList}`;

        if (!eventToggles || eventToggles.some(t => t.hazard_data_category === 'NWS alerts')) {
          map.current.addSource(polygon_events_source_id, {
            type: 'vector',
            tiles: [tilesPath],
          });

          const polygonEventsLayerConfig = {
            ...sampleDrawLayer,
            id: polygon_events_layer_id,
            source: polygon_events_source_id,
            'source-layer': polygon_source_layer,
            generateId: true,
            paint: {
              ...sampleDrawLayer.paint,
              'fill-color': eventTypesToMapboxColorExpression(),
            },
            metadata: {
              eventlayer: true,
              usermade: undefined,
            },
          };

          map.current.addLayer(polygonEventsLayerConfig);

          // Add event handlers for polygon events layer
          map.current.on('click', polygonEventsLayerConfig.id, (e) => 
            setLayerClicked({
              layer: polygonEventsLayerConfig,
              e: e,
              features: e.features,
            })
          );

          map.current.on('touchend', polygonEventsLayerConfig.id, (e) =>
            setLayerClicked({
              layer: polygonEventsLayerConfig,
              e: e,
              features: e.features,
            })
          );

          map.current.on('mouseenter', polygonEventsLayerConfig.id, () => {
            map.current.getCanvas().style.cursor = 'pointer';
          });

          map.current.on('mouseleave', polygonEventsLayerConfig.id, () => {
            map.current.getCanvas().style.cursor = 'grab';
          });
        }

        // Custom Events
        // Add custom polygon events tiles
        const customPolygonTiles = `${apiPrefix}/event_tiles/${custom_polygon_source_layer}/{z}/{x}/{y}.pbf`;
        const customPointTiles = `${apiPrefix}/event_tiles/${custom_point_source_layer}/{z}/{x}/{y}.pbf`;

        map.current.addSource(custom_polygon_events_source_id, {
          type: 'vector',
          tiles: [`${customPolygonTiles}?arg_group_guid=${group_guid}`],
        });

        map.current.addSource(custom_point_events_source_id, {
          type: 'vector',
          tiles: [`${customPointTiles}?arg_group_guid=${group_guid}`],
        });

        const customPolygonEventsLayerConfig = {
          ...sampleDrawLayer,
          id: custom_polygon_events_layer_id,
          source: custom_polygon_events_source_id,
          'source-layer': custom_polygon_source_layer,
          generateId: true,
          paint: {
            'fill-color': '#FFFFFF',
            'fill-opacity': 0.6,
          },
          metadata: {
            eventlayer: true,
            usermade: undefined,
          },
        };

        const customPointEventsLayerConfig = {
          ...samplePointLayer,
          id: custom_point_events_layer_id,
          source: custom_point_events_source_id,
          'source-layer': custom_point_source_layer,
          generateId: true,
          paint: {
            'circle-color': '#FFFFFF',
            'circle-radius': 5,
          },
          metadata: {
            eventlayer: true,
            usermade: undefined,
          },
        };

        map.current.addLayer(customPolygonEventsLayerConfig);
        map.current.addLayer(customPointEventsLayerConfig);

        // Add event handlers for custom events layers
        map.current.on('click', customPolygonEventsLayerConfig.id, (e) =>
          setLayerClicked({
            layer: customPolygonEventsLayerConfig,
            e: e,
            features: e.features,
          })
        );

        map.current.on('mouseenter', customPolygonEventsLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });

        map.current.on('mouseleave', customPolygonEventsLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        map.current.on('click', customPointEventsLayerConfig.id, (e) =>
          setLayerClicked({
            layer: customPointEventsLayerConfig,
            e: e,
            features: e.features,
          })
        );

        map.current.on('mouseenter', customPointEventsLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });

        map.current.on('mouseleave', customPointEventsLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        // Power Outage Layers
        if (poweroutageActive) {
          const polygonPoweroutageTiles = `${apiPrefix}/power_outage_tiles/${polygon_poweroutage_source_layer}/{z}/{x}/{y}.pbf`;
          const formattedHoursDatetime = selectedDatetime.minute(0).second(0).utc().format('YYYY-MM-DD HH:mm:ss');

          if (!eventToggles || eventToggles.some(t => t.hazard_data_category === 'Power Outage')) {
            map.current.addSource(polygon_poweroutage_source_id, {
              type: 'vector',
              tiles: [`${polygonPoweroutageTiles}?datetime_selected=${formattedHoursDatetime}`],
            });

            const polygonPoweroutageLayerConfig = {
              ...sampleDrawLayer,
              id: polygon_poweroutage_layer_id,
              source: polygon_poweroutage_source_id,
              'source-layer': polygon_poweroutage_source_layer,
              generateId: true,
              paint: {
                ...sampleDrawLayer.paint,
                'fill-color': [
                  'step',
                  ['*', ['/', ['get', 'CustomersOut'], ['get', 'CustomersTracked']], 100],
                  '#FFFFFF',
                  5,
                  '#ffffcc',
                  10,
                  '#a1dab4',
                  15,
                  '#41b6c4',
                  25,
                  '#2c7fb8',
                  35,
                  '#253494',
                ],
              },
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            map.current.addLayer(polygonPoweroutageLayerConfig);

            map.current.on('click', polygonPoweroutageLayerConfig.id, (e) =>
              setLayerClicked({
                layer: polygonPoweroutageLayerConfig,
                e: e,
                features: e.features,
              })
            );

            map.current.on('touchend', polygonPoweroutageLayerConfig.id, (e) =>
              setLayerClicked({
                layer: polygonPoweroutageLayerConfig,
                e: e,
                features: e.features,
              })
            );

            map.current.on('mouseenter', polygonPoweroutageLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mouseleave', polygonPoweroutageLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'grab';
            });
          }
        }

        // Earthquakes Layers
        if (!eventToggles || eventToggles.some(t => t.hazard_data_category === 'Earthquakes')) {
          map.current.addSource(earthquakes_source_layer, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: earthquakesData || [],
            },
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
          });

          const earthquakesClustersLayerConfig = {
            id: earthquakes_clusters_layer_id,
            source: earthquakes_source_layer,
            type: 'circle',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#FFA500',
                20,
                '#FFA500',
                50,
                '#FFA500',
              ],
              'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40],
              'circle-opacity': 0.5,
            },
            metadata: {
              eventlayer: true,
              usermade: undefined,
            },
          };

          const earthquakesClusterCounterLayerConfig = {
              id: earthquakes_cluster_count_layer_id,
              source: earthquakes_source_layer,
              type: 'symbol',
              filter: ['has', 'point_count'],
              layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
              },
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            const earthquakesUnclusteredPointLayerConfig = {
              id: earthquakes_unclustered_point_layer_id,
              source: earthquakes_source_layer,
              type: 'circle',
              filter: ['!', ['has', 'point_count']],
              paint: {
                'circle-color': '#FFA500',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#FFA500',
                'circle-opacity': 0.5,
              },
              generateId: true,
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            map.current.addLayer(earthquakesClustersLayerConfig);
            map.current.addLayer(earthquakesClusterCounterLayerConfig);
            map.current.addLayer(earthquakesUnclusteredPointLayerConfig);

            map.current.on('click', earthquakesClustersLayerConfig.id, (e) => {
              const features = map.current.queryRenderedFeatures(e.point, {
                layers: [earthquakesClustersLayerConfig.id],
              });
              const clusterId = features[0].properties.cluster_id;
              map.current
                .getSource(earthquakes_source_layer)
                .getClusterExpansionZoom(clusterId, (err, zoom) => {
                  if (err) return;

                  map.current.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  });
                });
            });

            map.current.on('click', earthquakesUnclusteredPointLayerConfig.id, (e) => {
              let features = Array.from(
                new Set(e.features.map((feature) => JSON.stringify(feature)))
              ).map((strFeature) => JSON.parse(strFeature));
              setLayerClicked({
                layer: earthquakesUnclusteredPointLayerConfig,
                e: e,
                features: features,
              });
            });

            map.current.on('mouseenter', earthquakesClustersLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mouseleave', earthquakesClustersLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'grab';
            });

            map.current.on('mouseenter', earthquakesUnclusteredPointLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mouseleave', earthquakesUnclusteredPointLayerConfig.id, () => {
              map.current.getCanvas().style.cursor = 'grab';
            });
          }

          // Hurricane Layers
          let hurricaneConeTiles = `${apiPrefix}/event_tiles/${hurricane_cones_layer}/{z}/{x}/{y}.pbf`;
          let hurricanePathTiles = `${apiPrefix}/event_tiles/${hurricane_paths_layer}/{z}/{x}/{y}.pbf`;
          let hurricanePointTiles = `${apiPrefix}/event_tiles/${hurricane_points_layer}/{z}/{x}/{y}.pbf`;

          const addHurricaneSource = (sourceId, tileUrl) => {
            map.current.addSource(sourceId, {
              type: 'vector',
              tiles: [
                `${tileUrl}?datetime_selected=${formattedDatetime}&end_datetime=${endDatetime}`,
              ],
            });
          };

          if (!eventToggles || eventToggles.some(t => t.hazard_data_category === 'Hurricane')) {
            addHurricaneSource(hurricane_cones_source_id, hurricaneConeTiles);
            addHurricaneSource(hurricane_paths_source_id, hurricanePathTiles);
            addHurricaneSource(hurricane_points_source_id, hurricanePointTiles);

            const hurricaneConesLayerConfig = {
              ...sampleDrawLayer,
              id: hurricane_cones_layer_id,
              source: hurricane_cones_source_id,
              'source-layer': hurricane_cones_layer,
              generateId: true,
              paint: {
                ...sampleDrawLayer.paint,
                'fill-color': '#FFFFFF',
              },
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            const hurricanePathsLayerConfig = {
              ...sampleLineLayer,
              id: hurricane_paths_layer_id,
              source: hurricane_paths_source_id,
              'source-layer': hurricane_paths_layer,
              generateId: true,
              paint: {
                ...sampleLineLayer.paint,
                'line-color': '#D3D3D3',
              },
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            const hurricanePointsLayerConfig = {
              ...samplePointLayer,
              id: hurricane_points_layer_id,
              source: hurricane_points_source_id,
              'source-layer': hurricane_points_layer,
              generateId: true,
              paint: {
                ...samplePointLayer.paint,
                'circle-color': hurricaneLayerTypesToMapboxColorExpression(),
                'circle-radius': 10,
              },
              metadata: {
                eventlayer: true,
                usermade: undefined,
              },
            };

            map.current.addLayer(hurricaneConesLayerConfig);
            map.current.addLayer(hurricanePathsLayerConfig);
            map.current.addLayer(hurricanePointsLayerConfig);

            // Add event handlers for hurricane layers
            [hurricaneConesLayerConfig, hurricanePathsLayerConfig, hurricanePointsLayerConfig].forEach(layerConfig => {
              map.current.on('click', layerConfig.id, (e) =>
                setLayerClicked({
                  layer: layerConfig,
                  e: e,
                  features: e.features,
                })
              );

              map.current.on('touchend', layerConfig.id, (e) =>
                setLayerClicked({
                  layer: layerConfig,
                  e: e,
                  features: e.features,
                })
              );

              map.current.on('mouseenter', layerConfig.id, () => {
                map.current.getCanvas().style.cursor = 'pointer';
              });

              map.current.on('mouseleave', layerConfig.id, () => {
                map.current.getCanvas().style.cursor = 'grab';
              });
            });
          }
        } catch (e) {
          console.error('Error in mapEventsEffect:', e);
          return;
        }
      
    } else {
      // If layers exist, just update their visibility and filters
      const layerIds = [
        polygon_events_layer_id,
        point_events_layer_id,
        custom_polygon_events_layer_id,
        custom_point_events_layer_id,
        polygon_poweroutage_layer_id,
        hurricane_cones_layer_id,
        hurricane_paths_layer_id,
        hurricane_points_layer_id,
        earthquakes_clusters_layer_id,
        earthquakes_cluster_count_layer_id,
        earthquakes_unclustered_point_layer_id
      ];

      layerIds.forEach(layerId => {
        if (layerExists(layerId)) {
          map.current.setLayoutProperty(
            layerId,
            'visibility',
            eventsVisibleToggle ? 'visible' : 'none'
          );
        }
      });

      // Update filters based on eventToggles
      if (layerExists(polygon_events_layer_id)) {
        map.current.setFilter(polygon_events_layer_id, [
          'in',
          'hazard_data_category',
          ...eventToggles.map(t => t.hazard_data_category),
        ]);
      }

      // Update earthquake filters
      if (layerExists(earthquakes_clusters_layer_id)) {
        const earthquakesEvent = eventToggles.find(t => t.hazard_data_category === 'Earthquakes');
        const earthquakesClusterFilter = earthquakesEvent ? ['has', 'point_count'] : ['has', 'point_count1'];
        map.current.setFilter(earthquakes_clusters_layer_id, earthquakesClusterFilter);
        map.current.setFilter(earthquakes_cluster_count_layer_id, earthquakesClusterFilter);
        map.current.setFilter(earthquakes_unclustered_point_layer_id, earthquakesEvent ? ['!', ['has', 'point_count']] : ['has', 'point_count1']);
      }

      // Update hurricane filters
      if (layerExists(hurricane_cones_layer_id)) {
        const hurricaneEvent = eventToggles.find(t => t.hazard_data_category === 'Hurricane');
        const hurricaneFilter = hurricaneEvent ? ['!', ['has', 'hazard_data_category']] : ['has', 'hazard_data_category'];
        map.current.setFilter(hurricane_cones_layer_id, hurricaneFilter);
        map.current.setFilter(hurricane_paths_layer_id, hurricaneFilter);
        map.current.setFilter(hurricane_points_layer_id, hurricaneFilter);
      }
    }
  }
}