import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import dayjs from 'dayjs';
import { ListGroupItem } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';

type SitrepSidebarItemProps = {
  sitrep: any;
  onClick: () => void;
  onEdit: (sitrep: any) => void;
};

const SitrepSidebarItem = ({
  sitrep,
  onClick,
  onEdit,
}: SitrepSidebarItemProps) => {
  return (
    <ListGroupItem
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.target instanceof HTMLButtonElement) {
          // Prevent buttons in the row from triggering onClick for the row.
          return;
        }
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
      className="d-flex justify-content-between align-items-center py-3"
    >
      <div style={{ flex: 1 }}>
        <strong style={{ display: 'block', marginBottom: '4px' }}>
          {sitrep.name}
        </strong>
        <em>
          {dayjs(sitrep.timestamp).utc().format('YYYY-MM-DD HH:mm [UTC]')}
        </em>
      </div>
      <div className="d-flex align-items-center">
        <StylishNewButton
          className="button--secondary button--icon"
          onClick={(e) => {
            // Prevent the row from being clicked.
            e.stopPropagation();
            onEdit(sitrep);
          }}
        >
          <SharedIcon iconName={'edit'} size={'sm'} />
        </StylishNewButton>
      </div>
    </ListGroupItem>
  );
};

export default SitrepSidebarItem;
