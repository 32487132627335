// hooks/useSectionTemplates.js

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import { selectGroupGuid } from 'slices/commonSelectors';

export const keys = {
  sectionTemplates: 'sectionTemplates',
};

// Fetch section templates
export const useSectionTemplates = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.sectionTemplates, group_guid],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.sitrepSectionTemplates}/`,
        {
          params: { group_guid },
        }
      );
      return response.data;
    },
    onError: (error) => {
      toast.error('Failed to fetch section templates.', {
        ...toastConfig,
        autoClose: false,
      });
    },
  });
};

// Create a section template
export const useCreateSectionTemplate = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepSectionTemplates}/`,
        {
          ...data,
          group_guid,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sectionTemplates]);
    },
    onError: (error) => {
      toast.error('Failed to create section template.', toastConfig);
    },
  });
};

// Update a section template
export const useUpdateSectionTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.put(
        `${config.apiGateway.sitrepSectionTemplates}/`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sectionTemplates]);
    },
    onError: (error) => {
      toast.error('Failed to update section template.', toastConfig);
    },
  });
};

// Delete a section template
export const useDeleteSectionTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${config.apiGateway.sitrepSectionTemplates}/`,
        {
          data: { id },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sectionTemplates]);
    },
    onError: (error) => {
      toast.error('Failed to delete section template.', toastConfig);
    },
  });
};
