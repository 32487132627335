import React, { useEffect, useState } from 'react';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Info from 'components/DesignSystems/text/Info';
import Sitrep from 'components/DisasterGPT/Sitrep';
import {
  useInitSitrepV2,
  useSitrepsV2,
  useSitrepV2,
  useRefreshSitrepV2,
} from 'components/DisasterGPT/hooks/useSitrepsV2';
import { useSitrepTemplates } from 'components/DisasterGPT/hooks/useSitrepTemplates';
import { toast } from 'react-toastify';
import sitrepName from 'components/DisasterGPT/DisasterGPTDataHelpers/sitrepName';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import LockIcon from '../../DesignSystems/LockIcon';
import { useSubscriptionLevel } from '../../SubscriptionManagement/useSubscriptionLevel';
import { defaultSitreps } from 'components/DisasterGPT/SitrepDefaults.js'

export default function EventAIAnalysis({
  event,
  locationInfo,
  map,
  mapHasLoaded,
  toggleDGPTOpen,
  selectedDatetime,
}) {
  const { paid } = useSubscriptionLevel();

  const [sitrepId, setSitrepId] = useState(null);
  const [isInitializingSitrep, setIsInitializingSitrep] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const initSitrepMutation = useInitSitrepV2();
  const { data: sitreps = [] } = useSitrepsV2();
  const {
    data: sitrep,
    isLoading: isSitrepLoading,
    isFetching: isSitrepFetching,
    isError: isSitrepError,
    refetch: refetchSitrep,
  } = useSitrepV2(sitrepId);

  const refreshSitrepMutation = useRefreshSitrepV2();

  useEffect(() => {
    setSitrepId(null);
  }, [event]);

  const {
    data: sitrepTemplates = [],
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
  } = useSitrepTemplates();

  const combinedTemplates = [...defaultSitreps,...sitrepTemplates]

  const sitrep_source = event?.hazard_name ? 'REPORTED EVENT' : 'CUSTOM EVENT';
  const sitrep_source_id =
    sitrep_source === 'REPORTED EVENT' ? event.instance_id : event.id;

  const existingSitrep = sitreps.find(
    (sitrep) => sitrep.sitrep_source_id === sitrep_source_id && !sitrep.archived
  );

  const handleGenerateSITREP = async () => {
    if (!selectedTemplate) {
      toast.error('A SITREP template must be selected to generate a SITREP.');
      return;
    }

    setIsInitializingSitrep(true);

    try {
      const sitrepIdResult = await initSitrepMutation.mutateAsync({
        sitrep_template_id: selectedTemplate.value,
        sitrep_source,
        sitrep_source_id,
        name: sitrepName('EVENTS', event),
        selectedDatetime,
      });

      setSitrepId(sitrepIdResult);
    } catch (e) {
      toast.error('Error generating SITREP: ' + e.message);
      console.error('SITREP Generation ERROR: ', e);
    } finally {
      setIsInitializingSitrep(false);
    }
  };

  const handleTemplateChange = (selectedOption) => {
    setSelectedTemplate(selectedOption);
  };

  const handleRefreshSitrep = () => {
    refreshSitrepMutation.mutate(
      { id: sitrepId, selectedDatetime },
      {
        onSuccess: () => {
          refetchSitrep();
        },
      }
    );
  };

  const eventType = event?.hazard_name || 'Custom Event';
  const placeName =
    locationInfo?.placeName ||
    event?.geolocation?.address ||
    'Unknown Location';

  const sitrepTemplateOptions = combinedTemplates.map((template) => ({
    value: template.id,
    label: template.name,
  }));

  const isLoadingSitrep =
    isInitializingSitrep ||
    isSitrepLoading ||
    isSitrepFetching ||
    initSitrepMutation.isLoading;

  return (
    <div>
      {!sitrep ? (
        isLoadingSitrep ? (
          <div>
            <div className="d-flex justify-content-center">
              Initializing SITREP...
            </div>
            <div className="d-flex justify-content-center">
              <i className="fa fa-spinner fa-pulse fs-6"></i>
            </div>
            <Info className="d-flex justify-content-center">
              Setting up the SITREP. Please wait...
            </Info>
          </div>
        ) : existingSitrep ? (
          <div className="analysis-content py-3 px-4">
            <div className="small-title">
              <span className="material-symbols-outlined fs-6 me-2">
                auto_awesome
              </span>
              DISASTER GPT
            </div>
            <div className="big-title">
              Welcome to <b>Disaster GPT</b>
            </div>
            <h6 className="mt-1 mb-1">
              DisasterGPT is currently in Preview. For bug reports and feature
              requests, please contact{' '}
              <a href="mailto:support@disastertech.com">
                support@disastertech.com
              </a>
            </h6>
            <div className="p-3 my-3 bg-inverse">
              <b>Event Type: </b>
              {eventType}
              <br />
              <b>Place: </b>
              {placeName}
            </div>
            <StylishNewButton
              onClick={() => {
                setSitrepId(existingSitrep.id);
              }}
              className="view-sitrep-button"
            >
              View SITREP
            </StylishNewButton>
          </div>
        ) : (
          <div className="analysis-content py-3 px-4">
            <div className="small-title">
              <span className="material-symbols-outlined fs-6 me-2">
                auto_awesome
              </span>
              DISASTER GPT
            </div>
            <div className="big-title">
              Welcome to <b>Disaster GPT</b>
            </div>
            <h6 className="mt-1 mb-1">
              DisasterGPT is currently in Preview. For bug reports and feature
              requests, please contact{' '}
              <a href="mailto:support@disastertech.com">
                support@disastertech.com
              </a>
            </h6>
            <div className="p-3 my-3 bg-inverse">
              <b>Event Type: </b>
              {eventType}
              <br />
              <b>Place: </b>
              {placeName}
            </div>
            <div className="d-flex flex-column align-items-start mb-3">
              <label htmlFor="sitrep-template-select" className="mb-2">
                Select SITREP Template:
              </label>
              <StylishNewSelect
                id="sitrep-template-select"
                options={sitrepTemplateOptions}
                value={selectedTemplate}
                onChange={handleTemplateChange}
                isClearable={true}
                placeholder="Select SITREP Template"
                isLoading={isTemplatesLoading}
                isDisabled={!paid || isTemplatesLoading || isTemplatesError}
              />
              {isTemplatesError && (
                <Info className="mt-2 text-danger">
                  Failed to load SITREP templates. Please try again later.
                </Info>
              )}
            </div>
            <StylishNewButton
              disabled={
                !paid ||
                isInitializingSitrep ||
                !selectedTemplate ||
                initSitrepMutation.isPending
              }
              onClick={handleGenerateSITREP}
              className="generate-button"
            >
              <span>Generate SITREP</span>
              {!paid ? <LockIcon /> : null}
            </StylishNewButton>
            {!selectedTemplate && (
              <Info className="mt-2 text-center">
                A SITREP template is required to generate a SITREP.
              </Info>
            )}
          </div>
        )
      ) : isSitrepError || refreshSitrepMutation.isError ? (
        <div className="text-center" style={{ marginTop: '20px' }}>
          <h5>There was an error with this SITREP.</h5>
          {refreshSitrepMutation.error && <p>{refreshSitrepMutation.error.message || 'Error refreshing SITREP.'}</p>}
          <StylishNewButton
            onClick={handleRefreshSitrep}
            disabled={refreshSitrepMutation.isLoading}
          >
            {refreshSitrepMutation.isLoading ? 'Refreshing...' : 'Refresh SITREP'}
          </StylishNewButton>
        </div>
      ) : (
        <Sitrep
          sitrep={sitrep}
          isSitrepLoading={isSitrepLoading || isSitrepFetching}
          map={map}
          mapHasLoaded={mapHasLoaded}
          toggleDGPTOpen={toggleDGPTOpen}
          name={sitrepName('EVENTS', event)}
          selectedDatetime={selectedDatetime}
        />
      )}
    </div>
  );
}
