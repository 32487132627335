import React, { useState } from 'react';
import {
  useCreateSitrepTemplate,
  useDeleteSitrepTemplate,
  useSitrepTemplates,
  useUpdateSitrepTemplate,
} from './hooks/useSitrepTemplates';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import SitrepTemplateEditDrawer from './SitrepTemplateEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import DeleteModal from '../DesignSystems/DeleteModal'; // Imported Modal
import { defaultSitreps } from './SitrepDefaults.js';

const SitrepTemplateManager = () => {
  const {
    data: sitrepTemplates = [],
    isLoading,
    isError,
  } = useSitrepTemplates();
  const createSitrepTemplateMutation = useCreateSitrepTemplate();
  const updateSitrepTemplateMutation = useUpdateSitrepTemplate();
  const deleteSitrepTemplateMutation = useDeleteSitrepTemplate();

  const [addDrawerOpen, toggleAddDrawer] = useToggle(false);
  const [editDrawerOpen, toggleEditDrawer] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // New state variables for delete confirmation modal
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer();
  };

  const handleOpenEditDrawer = (template) => {
    setSelectedTemplate(template);
    toggleEditDrawer();
  };

  const handleCloseDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer(false);
    toggleEditDrawer(false);
  };

  const handleSaveTemplate = (templateData) => {
    if (selectedTemplate) {
      updateSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating SITREP template:', error);
          toast.error('Failed to update SITREP template.', toastConfig);
        },
      });
    } else {
      createSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating SITREP template:', error);
          toast.error('Failed to create SITREP template.', toastConfig);
        },
      });
    }
  };

  const handleDeleteTemplate = (id) => {
    setDeleteId(id);
  };

  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteSitrepTemplateMutation.mutate(deleteId, {
        onSuccess: () => {
          toast.success('SITREP template deleted successfully.', toastConfig);
          setDeleteId(null);
        },
        onError: (error) => {
          console.error('Error deleting SITREP template:', error);
          toast.error('Failed to delete SITREP template.', toastConfig);
          setDeleteId(null);
        },
      });
    }
  };

  // New function to cancel deletion
  const handleCancelDelete = () => {
    setDeleteId(null);
  };

  const filteredTemplates = [
    ...defaultSitreps,
    ...sitrepTemplates,
  ].filter((template) =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return <div className="error-message">Error loading SITREP templates.</div>;
  }

  console.log('filteredTemplates', filteredTemplates);

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <StylishNewButton
          onClick={handleOpenAddDrawer}
          className="button--primary"
        >
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(val) => setSearchTerm(val)}
            placeholder="Search SITREP Templates..."
          />
        </div>
      </div>

      {isLoading ? (
        <div>Loading SITREP Templates...</div>
      ) : (
        <div
          className="d-flex flex-column flex-grow-1"
          style={{
            overflowY: 'auto',
          }}
        >
          <ListGroup className={'py-0 flex-grow-1'}>
            <SimpleEntityList
              entities={filteredTemplates}
              renderEntity={(template) => (
                <ListGroupItem
                  key={template.id}
                  className="d-flex justify-content-between align-items-center py-3"
                >
                  <div>
                    <strong>{template.name}</strong>
                  </div>
                  {defaultSitreps.find((s) => s.id === template.id) ? (
                    <div>
                      <span>(Default SITREP)</span>
                    </div>
                  ) : (
                    <div>
                      <StylishNewButton
                        className="button--secondary button--icon me-2"
                        onClick={() => handleOpenEditDrawer(template)}
                      >
                        <SharedIcon iconName={'edit'} size={'sm'} />
                      </StylishNewButton>
                      <StylishNewButton
                        className="button--secondary button--icon"
                        onClick={() => handleDeleteTemplate(template.id)}
                      >
                        <SharedIcon iconName={'delete'} size={'sm'} />
                      </StylishNewButton>
                    </div>
                  )}
                </ListGroupItem>
              )}
            />
          </ListGroup>
        </div>
      )}

      {(addDrawerOpen || editDrawerOpen) && (
        <SitrepTemplateEditDrawer
          show={addDrawerOpen || editDrawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveTemplate}
          template={selectedTemplate}
        />
      )}

      {deleteId ? (
        <DeleteModal
          title="Delete SITREP Template"
          onClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
        >
          Are you sure you want to delete this SITREP Template?
        </DeleteModal>
      ) : null}
    </>
  );
};

export default SitrepTemplateManager;
