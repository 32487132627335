import React from 'react';

type SimpleEntityListProps<T extends any[] = any[]> = {
  entities: T | undefined;
  header?: JSX.Element;
  renderEntity: (entity: T[number]) => JSX.Element;
};

const SimpleEntityList = <T extends any[]>({
  entities,
  header,
  renderEntity,
}: SimpleEntityListProps<T>) => {
  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center mb-2">{header}</div>
      <div>
        {entities?.map((entity, idx) => (
          <React.Fragment key={idx}> {renderEntity(entity)} </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SimpleEntityList;
