import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import PromptLibraryList from './PromptLibraryList';
import { selectGroupGuid } from 'slices/commonSelectors';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import DisasterChatManager from './DisasterChatManager';

const DisasterChatSidebar = ({ toggleDGPTOpen, DGPTOpen, onClose }) => {
  const group_guid = useSelector(selectGroupGuid);

  const [currentView, setCurrentView] = useState('disasterChat');

  const views = [
    { label: 'DisasterChat', value: 'disasterChat' },
    { label: 'Prompt Library', value: 'promptLibrary' },
  ];

  return (
    <div className="sidebar-content-wide d-flex flex-column h-100">
      <div className="sidebar-title" style={{ flex: '0 0 4rem' }}>
        <h4 className="m-0">DisasterChat</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => onClose()}
        >
          <SharedIcon iconName={'close'} />
        </StylishNewButton>
      </div>
      <StylishNewToggleButtonGroup
        className="p-2"
        data={views}
        selected={currentView}
        onChange={(val) => setCurrentView(val)}
      />

      {currentView === 'disasterChat' ? (
        <DisasterChatManager
          toggleDGPTOpen={toggleDGPTOpen}
          DGPTOpen={DGPTOpen}
        />
      ) : currentView === 'promptLibrary' ? (
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <PromptLibraryList group_guid={group_guid} />
        </div>
      ) : null}
    </div>
  );
};

export default DisasterChatSidebar;
