import React from 'react';
import { useRefreshSitrepV2, useSitrepV2 } from './hooks/useSitrepsV2';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import Sitrep from './Sitrep';

type SitrepLoaderProps = {
  sitrepId: string;
  selectedDatetime: string;
  map: any;
  mapHasLoaded: boolean;
  toggleDGPTOpen: () => void;
};
export const SitrepLoader: React.FC<SitrepLoaderProps> = ({
  sitrepId,
  map,
  mapHasLoaded,
  toggleDGPTOpen,
  selectedDatetime,
}) => {
  const refreshSitrepMutation = useRefreshSitrepV2();
  const {
    data: selectedSitrepData,
    isLoading: isSitrepLoading,
    isError: isSitrepError,
    refetch: refetchSitrep,
  } = useSitrepV2(sitrepId);

  const handleRefreshSitrep = () => {
    refreshSitrepMutation.mutate(
      { id: sitrepId, selectedDatetime, hardRefresh: true },
      {
        onSuccess: () => {
          refetchSitrep();
        },
      }
    );
  };

  return isSitrepLoading || refreshSitrepMutation.isPending ? (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <span>
        {refreshSitrepMutation.isPending
          ? 'Refreshing SITREP...'
          : 'Loading SITREP details...'}
      </span>
      <div>
        <i className="fa fa-spinner fa-pulse fs-6"></i>
      </div>
    </div>
  ) : isSitrepError || refreshSitrepMutation.isError ? (
    <div className="text-center" style={{ marginTop: '20px' }}>
      <h5>There was an error with this SITREP.</h5>
      {refreshSitrepMutation.error && (
        <p>
          {refreshSitrepMutation.error.message || 'Error refreshing SITREP.'}
        </p>
      )}
      <StylishNewButton onClick={handleRefreshSitrep}>
        Refresh SITREP
      </StylishNewButton>
    </div>
  ) : selectedSitrepData ? (
    <div className="sitrep-wrap" style={{ margin: '16px' }}>
      <Sitrep
        sitrep={selectedSitrepData}
        map={map}
        mapHasLoaded={mapHasLoaded}
        toggleDGPTOpen={toggleDGPTOpen}
        name={selectedSitrepData.name}
        selectedDatetime={selectedDatetime}
        fromSidebar={true}
      />
    </div>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <span>No SITREP data available.</span>
    </div>
  );
};
