import React from 'react';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';

import IconClose from '../../../assets/images/icon__times.svg';
import { eriScenarios, eriTableCols, eriTableRows } from '../constants/eri';

const MapImpactModels = (props) => {
  const {
    mapSettings,
    handleMapSettings,
    onClose,
  } = props;

  const eriScenario = mapSettings?.eriScenario;
  const eriActive = mapSettings?.eriActive;
  const models = [{ id: 0, name: 'Energy Reliability Index' }];

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Impact Models</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={onClose}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {models.map((m, idx) => (
          <div key={'model-' + idx}>
            {idx !== 0 && <hr className="dashed w-10 thin m-0" />}
            <div
              className={`py-3 ${idx === 0 ? 'pt-0' : ''} ${
                idx === models.length - 1 ? 'pb-0' : ''
              }`}
            >
              <div className="d-flex align-items-center">
                <span>{m.name}</span>
                <StylishSwitcher
                  className={'ms-auto'}
                  onChange={() =>
                    handleMapSettings({
                      ...mapSettings,
                      eriActive: !eriActive,
                    })
                  }
                  checked={eriActive}
                />
              </div>
              <div className="my-3">
                <StylishNewSelect
                  options={eriScenarios}
                  defaultValue={eriScenario}
                  value={eriScenario}
                  onChange={(e) =>
                    handleMapSettings({
                      ...mapSettings,
                      eriScenario: e.value,
                    })
                  }
                  placeholder={
                    eriScenarios?.find((s) => s.value === eriScenario)?.label
                  }
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              </div>
              <StylishNewTable
                classes={'tbody-p-0 layout-auto'}
                keyField={`id`}
                rows={eriTableRows}
                columns={eriTableCols}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapImpactModels;
