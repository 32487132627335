import Button from 'react-bootstrap/Button';
import { ButtonGroup } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import CollapsibleObjectivesList from './CollapsibleObjectivesList';
import CollapsibleActionsList from './CollapsibleActionsList';
import React from 'react';
import DashboardSidebar from './DashboardSidebar';
import { useCss } from 'react-use';
import IncidentSummary from './IncidentSummary';
import CollapsibleRosterList from './CollapsibleRosterList';
import CollapsibleStrategiesList from './CollapsibleStrategiesList';
import CollapsibleTacticsList from './CollapsibleTacticsList';

import CollapsibleIncidentResourceRequirementsList from './CollapsibleResourceRequestsList';
import CollapsibleResourceSitesList from './CollapsibleResourceSitesList';
import CollapsibleResourceSetsList from './CollapsibleResourceSetsList';
import CollapsibleStrikeTeamsList from './CollapsibleStrikeTeamsList';
import CollapsibleWorkAssignmentsList from './CollapsibleWorkAssignmentsList';
import CollapsibleMedAidStationsList from './CollapsibleMedAidStationsList';
import CollapsibleMedTransportationList from './CollapsibleMedTransportationList';
import CollapsibleHospitalsList from './CollapsibleHospitalsList';
import CollapsibleComsList from './CollapsibleComsList';
import { useIncident, usePeriods } from './hooks/incidentHooks';
import dayjs from 'dayjs';
import { useIncidentRoster } from './hooks/incidentRosterHooks';
import { useIncidentInfo } from './hooks/incidentInfoHooks';
import MobileWarningDialog from '../DesignSystems/MobileWarning/MobileWarningDialog';
import CollapsibleCustomResourcesList from './CollapsibleCustomResourceList';
import CollapsibleCustomResourceSIteTypesList from './CollapsibleCustomResourceSiteTypesList';

const IAPDashboard = () => {
  // trigger preloaded fetches
  useIncidentRoster();
  useIncidentInfo();

  const incident = useIncident();
  const periods = usePeriods();

  const sidebarCss = useCss({
    width: '20rem',
    minWidth: '20rem',
    borderLeft: '1px solid var(--neutral-700)',
  });

  if (incident.data === undefined) {
    return null;
  }

  const currentPeriod = periods.data?.find(
    (p) => p.id === incident.data?.current_period
  );
  const formatedPeriod = currentPeriod
    ? dayjs(currentPeriod.start_date_time).format('MM/DD/YYYY')
    : '';

  return (
    <MobileWarningDialog>
      <div className="d-flex">
        <div className="maincontent flex-grow-1">
          <div
            className="d-flex flex-column overflow-auto"
            style={{
              padding: '1.25rem 1.5rem',
              gap: '1.5rem',
            }}
          >
            <div>
              <h1>{incident.data.name}</h1>
              <div className="pt-1">Start Date: {formatedPeriod}</div>
            </div>
            <div className="d-flex flex-column gap-3">
              <IncidentSummary />
              <OperationalPeriodNavigator />

              <div className={'iap-section-title'}>Command</div>
              <div className={'iap-section-grouping'}>
                <CollapsibleObjectivesList />
                <CollapsibleStrategiesList />
                <CollapsibleTacticsList />
                <CollapsibleRosterList />
              </div>

              <div className={'iap-section-title'}>Operations</div>
              <div className={'iap-section-grouping'}>
                <CollapsibleActionsList />
                <CollapsibleWorkAssignmentsList />
                <CollapsibleResourceSetsList />
                <CollapsibleStrikeTeamsList />
                <CollapsibleComsList />

                <CollapsibleResourceSitesList />
                <CollapsibleIncidentResourceRequirementsList />
                <CollapsibleCustomResourcesList />
                <CollapsibleCustomResourceSIteTypesList />
              </div>

              <div className={'iap-section-title'}>Medical</div>
              <div className={'iap-section-grouping'}>
                <CollapsibleMedAidStationsList />
                <CollapsibleMedTransportationList />
                <CollapsibleHospitalsList />
              </div>
            </div>
          </div>
        </div>

        <div className={sidebarCss}>
          <DashboardSidebar />
        </div>
      </div>
    </MobileWarningDialog>
  );
};

export default IAPDashboard;

const OperationalPeriodNavigator = () => {
  // Probably better to use css-grid to get this to look correct with prev/next being the same size.
  return (
    <ButtonGroup className="button-group switcher">
      <Button className="button button--primary flex-shrink-1 flex-grow-0 opacity-50">
        <SharedIcon iconName="arrow_left" />
        <span>Previous</span>
      </Button>
      <div className="flex-grow-1 bg-primary-800 h-100 d-flex p-1 align-items-center justify-content-center">
        Operational period
      </div>
      <Button className="button button--primary flex-shrink-1 flex-grow-0 opacity-50">
        <span>Next</span>
        <SharedIcon iconName="arrow_right" />
      </Button>
    </ButtonGroup>
  );
};
