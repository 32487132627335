import React, { useMemo, useState } from 'react';
import {
  useCreatePrompt,
  useEditPrompt,
  usePrompts,
} from './hooks/usePromptLibraryHooks';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import PromptDrawer from './PromptDrawer';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';

const PromptLibraryList = ({ group_guid }) => {
  const { data: prompts = [] } = usePrompts(group_guid);
  const createPrompt = useCreatePrompt();
  const editPrompt = useEditPrompt();

  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredPrompts = useMemo(() => {
    return prompts.filter((prompt) =>
      prompt.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [prompts, searchTerm]);

  const handleOpenEdit = (prompt) => {
    setSelectedEntity(prompt);
    toggleEditDrawer();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <StylishNewButton onClick={toggleAddDrawer} className="button--primary">
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(val) => setSearchTerm(val)}
            placeholder="Search Prompts..."
          />
        </div>
      </div>

      <div style={{ flexGrow: 1, overflowY: 'auto' }}>
        <ListGroup className={'py-0'}>
          <SimpleEntityList
            entities={filteredPrompts}
            renderEntity={(prompt) => (
              <ListGroupItem
                key={prompt.id}
                className="d-flex justify-content-between align-items-center py-3"
              >
                <div>
                  <strong>
                    {prompt.title.length > 50
                      ? `${prompt.title.slice(0, 50)}...`
                      : prompt.title}
                  </strong>
                </div>
                <div>
                  <StylishNewButton
                    className="button--secondary button--icon"
                    onClick={() => handleOpenEdit(prompt)}
                  >
                    <SharedIcon iconName={'edit'} size={'sm'} />
                  </StylishNewButton>
                </div>
              </ListGroupItem>
            )}
          />
        </ListGroup>
      </div>

      {addOpen && (
        <PromptDrawer toggle={toggleAddDrawer} onSubmit={createPrompt.mutate} />
      )}
      {editOpen && selectedEntity && (
        <PromptDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
          onSubmit={(data) => editPrompt.mutate({ ...data })}
        />
      )}
    </div>
  );
};

export default PromptLibraryList;
