import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';

import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog.js';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';
import { Dropdown } from 'react-bootstrap';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

import { endLoading, startLoading } from 'reducers/loading/loading.action.js';
import { deleteCoreComponent, fetchCoreComponents } from 'actions/ai2fpActions.js';

import CollapsibleResourceSitesList from 'components/IAP/CollapsibleResourceSitesList';
import CollapsibleResourceSetsList from 'components/IAP/CollapsibleResourceSetsList';
import CollapsibleWorkAssignmentsList from 'components/IAP/CollapsibleWorkAssignmentsList';

import { useResourceSites } from 'components/IAP/hooks/resourceSiteHooks';
import { useSubscriptionLevel } from '../../SubscriptionManagement/useSubscriptionLevel';

const flyToGeolocation = (map, item) => {
  if (
    item?.geolocation?.geojson?.data?.features?.length
  ) {
    var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
    map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
  }
};

const MapGeolocations = ({
  map,
  mapHasLoaded,
  apiPrefix,
  selectedGeolocation,
  setLayerClicked,
  onClose,
  layerState
}) => {
  const { lockIncident } = useSubscriptionLevel();
  const reduxDispatch = useDispatch();
  const { data: resourceSites } = useResourceSites();

  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const reduxCoreComponents = useSelector((state) => state.app.coreComponents);
  const reduxCurrentIncident = useSelector((state) => state.app.currentIncident);

  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteGeolocationType, setDeleteGeolocationType] = useState(null);
  const [deleteGeolocationData, setDeleteGeolocationData] = useState(null);
  const [AORSelected, setAORSelected] = useState();
  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);

  const handleClickClose = () => {
    onClose();
  };

  const sidebarSecondaryHandler = (show, key) => {
    setShowSidebarSecondary(show);
    setSidebarSecondaryActiveItem(key);
  };

  const AORClicked = (type, aor) => {
    setAORSelected(aor);
    if (type === 'edit') setAORCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('AORs');
      setDeleteGeolocationData(aor);
      setShowConfirmDialog(true);
    }
  };

  const deleteAORClicked = async () => {
    reduxDispatch(startLoading());
    await reduxDispatch(deleteCoreComponent(deleteGeolocationData));

    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  useEffect(() => {
    if (selectedGeolocation?.type === 'AOR') {
      setAORSelected(selectedGeolocation?.geolocation);
    }
  }, [selectedGeolocation]);

  useEffect(() => {
    if (
      reduxCurrentlySelectedGroup?.group_guid &&
      map &&
      reduxCurrentIncident &&
      mapHasLoaded
    ) {
      if (!reduxCoreComponents) {
        reduxDispatch(fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid));
      }
    }
  }, [reduxCurrentlySelectedGroup, map, mapHasLoaded]);

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [reduxCoreComponents]);

  function icsFlyToClicked(sidebarSecondaryActiveItem, item) {
    if (
      item?.resource_set_id &&
      item?.resource_site_id &&
      !item?.geolocation
    ) {
      const site = resourceSites.find((rs) => rs.id === item.resource_site_id);
      if (site) {
        flyToGeolocation(map, site);
      }
    } else {
      flyToGeolocation(map, item);
    }
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setShowSidebarSecondary(false);
              setSidebarSecondaryActiveItem(null);
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        <h4 className="m-0">Geolocations</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary ? (
          <>
            <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
              <span className="text-truncate me-3">AORs</span>
              <StylishSwitcher
                className={'ms-auto'}
                checked={layerState.showAORsOnMap}
                onChange={() => layerState.setShowAORsOnMap(!layerState.showAORsOnMap)}
              />
              <span
                className="px-3 cursor-pointer"
                onClick={() => sidebarSecondaryHandler(true, 'Geolocations AORs')}
              >
                <img src={IconArrowRight} alt="" className="img-h-16" />
              </span>
            </div>
            {!lockIncident ? (
              <>
                <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                  <span className="text-truncate me-3">Resource Sites</span>
                  <StylishSwitcher
                    className={'ms-auto'}
                    checked={layerState.showResourceSitesOnMap}
                    onChange={() => layerState.setShowResourceSitesOnMap(!layerState.showResourceSitesOnMap)}
                  />
                  <span
                    className="px-3 cursor-pointer"
                    onClick={() => sidebarSecondaryHandler(true, 'Geolocations Resource Sites')}
                  >
                    <img src={IconArrowRight} alt="" className="img-h-16" />
                  </span>
                </div>

                <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                  <span className="text-truncate me-3">Resource Sets</span>
                  <StylishSwitcher
                    className={'ms-auto'}
                    checked={layerState.showResourceSetsOnMap}
                    onChange={() => layerState.setShowResourceSetsOnMap(!layerState.showResourceSetsOnMap)}
                  />
                  <span
                    className="px-3 cursor-pointer"
                    onClick={() => sidebarSecondaryHandler(true, 'Geolocations Resource Sets')}
                  >
                    <img src={IconArrowRight} alt="" className="img-h-16" />
                  </span>
                </div>

                <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                  <span className="text-truncate me-3">Work Assignments</span>
                  <StylishSwitcher
                    className={'ms-auto'}
                    checked={layerState.showWorkAssignmentsOnMap}
                    onChange={() => layerState.setShowWorkAssignmentsOnMap(!layerState.showWorkAssignmentsOnMap)}
                  />
                  <span
                    className="px-3 cursor-pointer"
                    onClick={() => sidebarSecondaryHandler(true, 'Geolocations Work Assignments')}
                  >
                    <img src={IconArrowRight} alt="" className="img-h-16" />
                  </span>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            {sidebarSecondaryActiveItem === 'Geolocations AORs' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">AORs</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setAORSelected();
                      setAORCreationDialogActive(true);
                    }}
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-4" />
                {reduxCoreComponents?.CCs?.['Areas of Responsibility']?.map(
                  (aor, idx) => {
                    return (
                      <React.Fragment key={aor.id}>
                        {idx !== 0 && <hr className="dashed w-10 thin m-0" />}
                        <div
                          className={`d-flex align-items-center py-3 ${
                            idx === 0 ? 'pt-0' : ''
                          } ${
                            idx === reduxCoreComponents.CCs['Areas of Responsibility'].length - 1
                              ? 'pb-0'
                              : ''
                          }`}
                        >
                          <span className="text-truncate">{aor.name}</span>
                          <Dropdown className="flex-shrink-0 ms-auto">
                            <Dropdown.Toggle className="button--icon">
                              <SharedIcon iconName="more_vert" bold />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item
                                href="#"
                                onClick={() => AORClicked('edit', aor)}
                              >
                                <SharedIcon iconName="stylus" />
                                Edit AORs
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => AORClicked('delete', aor)}
                              >
                                <SharedIcon iconName="delete" />
                                Delete AORs
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => flyToGeolocation(map, aor)}
                              >
                                <SharedIcon iconName="location_on" />
                                View Location
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
              </>
            )}

            {sidebarSecondaryActiveItem === 'Geolocations Resource Sites' && (
              <CollapsibleResourceSitesList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}

            {sidebarSecondaryActiveItem === 'Geolocations Resource Sets' && (
              <CollapsibleResourceSetsList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}

            {sidebarSecondaryActiveItem === 'Geolocations Work Assignments' && (
              <CollapsibleWorkAssignmentsList
                onMap={true}
                flyTo={(e) => icsFlyToClicked(sidebarSecondaryActiveItem, e)}
              />
            )}
          </>
        )}
      </div>

      {aorCreationDialogActive && (
        <CoreComponentCreationDialog
          show={aorCreationDialogActive}
          onClose={() => {
            setAORCreationDialogActive(false);
          }}
          ccType="Areas of Responsibility"
          existingCC={AORSelected}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={`Delete ${deleteGeolocationType}`}
          dialogContent="Are you sure you want to delete this? This action cannot be reversed."
          onClose={() => {
            setShowConfirmDialog(false);
            setDeleteGeolocationType(null);
            setDeleteGeolocationData(null);
          }}
          onConfirm={deleteAORClicked}
        />
      )}
    </div>
  );
};

export default MapGeolocations;