import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import { ResourceSet, useUpdateResourceSet } from './hooks/resourceSetHooks';
import { useResourceSites } from './hooks/resourceSiteHooks';
import FormSelectField from './formFields/FormSelectField';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { resource_set_statuses } from 'components/ICS/ICSConstants';
import { ResourceTypes } from '../IncidentConfig/ResourceTypes';
import {
  CustomResourceType,
  useCustomResources,
} from './hooks/customResourceTypeHooks';
import CustomResourceAddDrawer from './CustomResourceAddDrawer';
import FormDateTimeField from './formFields/FormDateTimeField';
import { useSelector } from 'react-redux';
import { useIncidentsInCurrentGroup } from './hooks/commonHooks';

type FormFields = {
  id: UUID;
  resource_set_id: UUID;
  group_guid: UUID;
  name: string;
  description: string;
  geolocation: any;
  resource_site_id: UUID;
  kind: string;
  type: string;
  quantity: number;
  status: string;
  consolidation_target_id: UUID;
  archived: boolean;
  date_time_ordered: string;
  eta: string;
  incidents: UUID[];
};

// Correctly typed RootState
interface RootState {
  app: {
    incidents: Array<{ id: string; name: string }>;
    currentIncident: { id: string; name: string };
  };
}

const ResourceSetEditDrawer: React.FC<EditEntityDrawerProps<ResourceSet>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { ...editedEntity },
  });
  const { handleSubmit, setValue } = form;

  const { data: resourceSites } = useResourceSites();
  const { data: customResources = [] } = useCustomResources();

  const mutation = useUpdateResourceSet(editedEntity.resource_set_id);
  const [geolocation, setGeolocation] = useState(editedEntity.geolocation);
  const [showCustomResourceDrawer, setShowCustomResourceDrawer] = useState(
    false
  );

  // Fetch incidents and current incident with proper types
  const incidents = useIncidentsInCurrentGroup();
  const currentIncident = useSelector(
    (state: RootState) => state.app.currentIncident
  );

  // Set the default incidents value
  React.useEffect(() => {
    if (editedEntity.incidents && editedEntity.incidents.length > 0) {
      setValue('incidents', editedEntity.incidents);
    } else if (currentIncident?.id) {
      setValue('incidents', [currentIncident.id]);
    }
  }, [currentIncident, editedEntity.incidents, setValue]);

  const submitResourceSetEdit = async (formData: FormFields) => {
    const kind = formData.kind;
    const fullResource =
      ResourceTypes.find((r) => r.name === kind) ||
      customResources.find((r) => r.name === kind);

    const payload = {
      ...formData,
      resource_set_id: editedEntity.resource_set_id,
      geolocation: geolocation,
      incidents: formData.incidents,
      type: fullResource
        ? 'Type' in fullResource
          ? (fullResource as { Type: string }).Type
          : (fullResource as CustomResourceType).type
        : '',
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    const newArchivedValue = !editedEntity.archived;
    await mutation.mutateAsync({
      ...editedEntity,
      archived: newArchivedValue,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Resource Set">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitResourceSetEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Resource"
                fieldName="kind"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={[
                  ...ResourceTypes.map((type) => ({
                    value: type.name,
                    label: type.name,
                  })),
                  ...customResources.map((resource) => ({
                    value: resource.name,
                    label: resource.name,
                  })),
                ]}
                variant="half"
              />
              <span
                className="text-primary mt-2 cursor-pointer"
                onClick={() => setShowCustomResourceDrawer(true)}
              >
                Add Custom Resource
              </span>
            </Row>
            <Row>
              <FormSelectField
                title="Resource Site"
                fieldName="resource_site_id"
                options={
                  resourceSites?.map((r) => ({ label: r.name, value: r.id })) ||
                  []
                }
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Quantity"
                fieldName="quantity"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Set Status"
                fieldName="status"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={
                  resource_set_statuses?.map((u) => ({
                    label: u.label,
                    value: u.value,
                  })) || []
                }
                variant="half"
              />
            </Row>
            <Row>
              <FormDateTimeField
                title="Date Time Ordered"
                fieldName="date_time_ordered"
                variant="half"
                type="datetime-local"
              />
            </Row>
            <Row>
              <FormDateTimeField
                title="ETA"
                fieldName="eta"
                variant="half"
                type="datetime-local"
              />
            </Row>
            <Row>
              <div className="m-0">
                <label className="form-label">Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
            <Row>
              <FormSelectField
                title="Incidents"
                fieldName="incidents"
                options={incidents.map((incident) => ({
                  label: incident.name,
                  value: incident.id,
                }))}
                variant="full"
                isMulti
                defaultValue={editedEntity.incidents}
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived && 'Deactivate') || 'Activate'}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
      {showCustomResourceDrawer && (
        <CustomResourceAddDrawer
          toggle={() => setShowCustomResourceDrawer(false)}
        />
      )}
    </DrawerWrapper>
  );
};

export default ResourceSetEditDrawer;
