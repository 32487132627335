// useEventLayers.js
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import axios from 'axios';
import mapEventsEffect from '../mapEffects/mapEventsEffect';

dayjs.extend(utc);

export const useEventLayers = (map, mapHasLoaded, apiPrefix, mapSettings, setLayerClicked) => {
  // State management
  const [eventsVisibleToggle, setEventsVisibleToggle] = useState(false);
  const [ethelData, setEthelData] = useState();
  const [earthquakesData, setEarthquakesData] = useState();
  const [poweroutageData, setPoweroutageData] = useState();
  const [previousDatetime, setPreviousDatetime] = useState(mapSettings.selectedDatetime);
  const [previousEventsVisibleToggle, setPreviousEventsVisibleToggle] = useState(false);

  // Redux selectors
  const reduxFeatureFlags = useSelector((state) => state.app.featureFlags);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const eventToggles = useSelector((state) => state.map.eventToggles);

  // Effect to fetch event data when visibility toggled
  useEffect(() => {
    if (eventsVisibleToggle && reduxFeatureFlags) {
      const fetchEventData = async () => {
        const formattedDatetime = mapSettings.selectedDatetime.utc().format('YYYY-MM-DD HH:mm:ss');
        const hazardNameFilterList = '';
        const authHeader = `Bearer ${sessionStorage['accessToken']}`;

        try {
          // Fetch ethel data
          const ethelResponse = await axios(`${apiPrefix}/dice/map/ethelData_alternate`, {
            method: 'GET',
            headers: {
              Authorization: authHeader
            },
            params: {
              selectedDatetime: formattedDatetime,
              hazardNameFilterList
            }
          });
          setEthelData(ethelResponse.data);

          // Fetch earthquakes data
          const endDatetime = dayjs(formattedDatetime).format('YYYY-MM-DD HH:mm:ss');
          const earthquakesResponse = await axios(`${apiPrefix}/dice/map/earthquakesData`, {
            method: 'GET',
            headers: {
              Authorization: authHeader
            },
            params: {
              selectedDatetime: formattedDatetime,
              endDatetime
            }
          });
          setEarthquakesData(earthquakesResponse.data);

          // Fetch power outage data if enabled
          if (reduxFeatureFlags.includes('POWER_OUTAGES')) {
            const powerOutageDatetime = mapSettings.selectedDatetime
              .minute(0)
              .second(0)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
            
            const powerOutageResponse = await axios(`${apiPrefix}/dice/map/poweroutageData`, {
              method: 'GET',
              headers: {
                Authorization: authHeader
              },
              params: {
                selectedDatetime: powerOutageDatetime
              }
            });
            setPoweroutageData(powerOutageResponse.data);
          }
        } catch (error) {
          console.error('Error fetching event data:', error);
        }
      };

      fetchEventData();
    }
  }, [eventsVisibleToggle, reduxFeatureFlags, apiPrefix, mapSettings?.selectedDatetime]);

  // Effect to initialize and update map layers
  useEffect(() => {
    if (!mapHasLoaded || !map.current) return;

    if (!eventsVisibleToggle) {
      // Hide all layers if they exist
      const layerIds = [
        'polygon_events_layer_id',
        'point_events_layer_id',
        'custom_polygon_events_layer_id',
        'custom_point_events_layer_id',
        'polygon_poweroutage_layer_id',
        'public.hurricane_cones_layer_id',
        'public.hurricane_paths_layer_id',
        'public.hurricane_points_layer_id',
        'earthquakes_clusters_layer_id',
        'earthquakes_cluster_count_layer_id',
        'earthquakes_unclustered_point_layer_id'
      ];

      layerIds.forEach(layerId => {
        if (map.current.getLayer(layerId)) {
          map.current.setLayoutProperty(layerId, 'visibility', 'none');
        }
      });
      return;
    }

    const forceRefresh = !previousDatetime.isSame(mapSettings.selectedDatetime) || 
                        (!previousEventsVisibleToggle && eventsVisibleToggle);
    
    // Initialize or update layers
    if (earthquakesData && reduxFeatureFlags && !reduxFeatureFlags.includes("SUPPRESS_EVENT_TILES")) {
      mapEventsEffect(
        map,
        mapHasLoaded,
        ethelData,
        eventsVisibleToggle,
        mapSettings.selectedDatetime,
        setLayerClicked,
        apiPrefix,
        earthquakesData,
        poweroutageData,
        reduxFeatureFlags.includes('POWER_OUTAGES'),
        eventToggles,
        reduxFeatureFlags,
        reduxCurrentlySelectedGroup?.group_guid,
        forceRefresh
      );
    }

    if (forceRefresh) {
      setPreviousDatetime(mapSettings.selectedDatetime);
      setPreviousEventsVisibleToggle(true);
    }
    else
    {
      setPreviousEventsVisibleToggle(false)
    }
  }, [
    map,
    mapHasLoaded,
    eventsVisibleToggle,
    ethelData,
    earthquakesData,
    poweroutageData,
    eventToggles,
    reduxFeatureFlags,
    reduxCurrentlySelectedGroup?.group_guid,
  ]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (map.current) {
        const layerIds = [
          'polygon_events_layer_id',
          'point_events_layer_id',
          'custom_polygon_events_layer_id',
          'custom_point_events_layer_id',
          'polygon_poweroutage_layer_id',
          'public.hurricane_cones_layer_id',
          'public.hurricane_paths_layer_id',
          'public.hurricane_points_layer_id',
          'earthquakes_clusters_layer_id',
          'earthquakes_cluster_count_layer_id',
          'earthquakes_unclustered_point_layer_id'
        ];

        layerIds.forEach(layerId => {
          if (map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
          }
        });

        const sourceIds = [
          'polygon_events_source_id',
          'point_events_source_id',
          'custom_polygon_events_source_id',
          'custom_point_events_source_id',
          'polygon_poweroutage_source_id',
          'public.hurricane_cones_source_id',
          'public.hurricane_paths_source_id',
          'public.hurricane_points_source_id',
          'earthquakes_source_layer'
        ];

        sourceIds.forEach(sourceId => {
          if (map.current.getSource(sourceId)) {
            map.current.removeSource(sourceId);
          }
        });
      }
    };
  }, []);

  return {
    eventsVisibleToggle,
    setEventsVisibleToggle,
    ethelData,
    setEthelData,
    earthquakesData,
    setEarthquakesData,
    poweroutageData,
    setPoweroutageData
  };
};