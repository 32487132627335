// useDisasterChatsHooks.js

import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { pollDChat, postDChat } from 'slices/dchatSlice';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import {
  selectUserGuid,
  selectGroupGuid,
  selectIncident,
  useAppSelector,
} from '../../../slices/commonSelectors';

export const keys = {
  base: 'disasterChats',
};

export const useDisasterChats = () => {
  const user_guid = useSelector(selectUserGuid);
  const incident = useAppSelector(selectIncident);

  return useQuery({
    queryKey: [keys.base, incident?.id],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.disasterchat}/`, {
        params: { user_guid, incident_id: incident?.id },
      });
      return response.data;
    },
    staleTime: 0, // Ensure the query refetches immediately after invalidation
    onSuccess: (data) => {
      // Handle success if needed
    },
    onError: (error) => {
      toast.error('Failed to fetch disaster chats.', {
        ...toastConfig,
        autoClose: false,
      });
    },
  });
};

export const useCreateDisasterChat = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const current_sitrep_id = useSelector(
    (state) => state.dchat.current_sitrep_id
  );

  return useMutation({
    mutationFn: async (data) => {
      const payload = {
        ...data,
        user_guid,
        current_sitrep_id,
        group_guid,
        incident,
      };
      return await axios.post(`${config.apiGateway.disasterchat}`, payload);
    },
    onMutate: () => {
      // Optional: Optimistically update the cache or UI here
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    },
    onError: (error) => {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    },
  });
};

export const useUpdateDisasterChat = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const current_sitrep_id = useSelector(
    (state) => state.dchat.current_sitrep_id
  );
  const incident = useAppSelector(selectIncident);

  return useMutation({
    mutationFn: async (data) => {
      const payload = {
        ...data,
        user_guid,
        current_sitrep_id,
        group_guid,
        incident,
      };
      return await axios.put(`${config.apiGateway.disasterchat}`, payload);
    },
    onMutate: () => {
      // Optional: Optimistically update the cache or UI here
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    },
    onError: (error) => {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    },
  });
};

export const useDeleteDisasterChat = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dchat_id) => {
      return await axios.delete(`${config.apiGateway.disasterchat}`, {
        data: { dchat_id },
      });
    },
    onMutate: () => {
      // Optional: Optimistically update the cache or UI here
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    },
    onError: (error) => {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    },
  });
};

export const useCancelDisasterChat = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axios.post(`${config.apiGateway.disasterchat}/cancel`, data);
    },
    onMutate: () => {
      // Optional: Optimistically update the cache or UI here
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    },
    onError: (error) => {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    },
  });
};

export const usePollDChat = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (dchatId) => {
    dispatch(pollDChat(dchatId));
    // Invalidate queries immediately after dispatch
    queryClient.invalidateQueries({ queryKey: [keys.base] });
  };
};

export const usePostDChat = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const incident = useAppSelector(selectIncident);

  return async (data) => {
    try {
      // Dispatch the Redux action
      await dispatch(postDChat({ ...data, incident }));

      // Invalidate the queries to refresh the chats list
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    } catch (error) {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    }
  };
};
