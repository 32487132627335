import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Accordion from 'react-bootstrap/Accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
  fetchSOPs,
  uploadSOP,
  deleteSOP,
  downloadSOP,
} from 'slices/dchatSlice';

import { fileExtensions } from './DisasterGPTConstants';

import './SOPs.css';
import './CustomAccordion.css';

const SOPs = ({ selections = [], setSelections }) => {
  const reduxDispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for file upload

  const reduxSOPs = useSelector((state) => state.dchat.SOPs);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    setLoading(true); // Set loading to true when starting upload
    reduxDispatch(uploadSOP(file))
      .then(() => {
        setLoading(false); // Stop loading after successful upload
      })
      .catch(() => {
        setLoading(false); // Stop loading even on failure
      });
  };

  useEffect(() => {
    reduxDispatch(fetchSOPs());
  }, [reduxDispatch]);

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row.id]);
    } else {
      setSelections(selections.filter((id) => id !== row.id));
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelections(rows.map((row) => row.id));
    } else {
      setSelections([]);
    }
  };

  const handleDownloadLinkClick = (fileId, filename) => {
    reduxDispatch(downloadSOP(fileId, filename));
  };

  const columns = [
    {
      dataField: 'filename',
      text: 'File Name',
      sort: true,
      attrs: { title: 'File Name' },
      formatter: (cell, row) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default link behavior
            handleDownloadLinkClick(row.id, row.filename);
          }}
          style={{ textDecoration: 'underline', color: '#0078d4' }}
        >
          {row.filename}
        </a>
      ),
    },
    {
      dataField: 'filetype',
      text: 'File Type',
      sort: true,
      attrs: { title: 'File Type' },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: true,
      attrs: { title: 'Delete' },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <StylishNewButton onClick={() => reduxDispatch(deleteSOP(row.id))}>
              Delete
            </StylishNewButton>
          </div>
        );
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    hideSelectAll: true,
    selected: selections,
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelections([...selections, row.id]);
      } else {
        setSelections(selections.filter((id) => id !== row.id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelections(rows.map((row) => row.id));
      } else {
        setSelections([]);
      }
    },
  };

  // Generate the accept string from fileExtensions
  const accept = fileExtensions.join(',');

  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="border-b">
            Uploaded Files
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: '13px' }}>
                Upload Files to optimize DisasterChat.
              </p>
              <p style={{ fontSize: '13px' }}>
                Supported File Types: .csv, .doc, .docx, .md, .msg, .pdf, .pptx,
                .txt, .url, .xls, .xlsx
              </p>
              <div>
                <input type="file" accept={accept} onChange={onFileChange} />
              </div>
              <div>
                {loading ? (
                  <div>Loading...</div> // Display loading indicator during upload
                ) : (
                  <StylishNewButton
                    onClick={onFileUpload}
                    disabled={!file} // Disable button if no file selected
                  >
                    Upload!
                  </StylishNewButton>
                )}
              </div>
              <StylishNewTable
                keyField={'id'}
                rows={reduxSOPs}
                columns={columns}
                // selectRow={selectRow}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SOPs;
