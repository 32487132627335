import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from '../DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from '../DesignSystems/New/SimpleEntityList';
import React, { useMemo, useState } from 'react';
import { setCurrentSitrepId, setSelectedDChat } from '../../slices/dchatSlice';
import { useDispatch } from 'react-redux';
import {
  useDeleteDisasterChat,
  useDisasterChats,
} from './hooks/useDisasterChats';
import DisasterChatEditDrawer from './DisasterChatEditDrawer';
import DeleteModal from '../DesignSystems/DeleteModal';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';

type DisasterChatManagerProps = {
  toggleDGPTOpen: () => void;
  DGPTOpen: boolean;
};
const DisasterChatManager = ({
  toggleDGPTOpen,
  DGPTOpen,
}: DisasterChatManagerProps) => {
  const dispatch = useDispatch();

  const { data: chats = [], isLoading } = useDisasterChats();
  const deleteDisasterChat = useDeleteDisasterChat();

  const [chatToEdit, setChatToEdit] = useState<any | undefined>();
  const [chatToDelete, setChatToDelete] = useState<any | undefined>();
  const [filterText, setFilterText] = useState('');

  const handleChatClick = (chat: any) => {
    const startedOpen = DGPTOpen;
    dispatch(setCurrentSitrepId(undefined));
    dispatch(setSelectedDChat(chat));
    toggleDGPTOpen();
    if (startedOpen) {
      toggleDGPTOpen();
    }
  };

  const handleAddChat = () => {
    // "Add" means start a new chat (empty chat object)
    handleChatClick({});
  };

  const handleDeleteChat = (chat: any) => {
    setChatToDelete(chat);
  };

  const handleConfirmDelete = () => {
    if (chatToDelete) {
      deleteDisasterChat.mutate(chatToDelete.id, {
        onSettled: () => {
          setChatToDelete(undefined);
        },
      });
    }
  };

  const handleEditChat = (chat: any) => {
    setChatToEdit(chat);
  };

  const filteredChats = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    return chats.filter((chat: any) => {
      if (typeof chat.name === 'string') {
        return chat.name.toLowerCase().includes(lowerCaseFilter);
      } else {
        console.warn('Encountered chat with invalid name:', chat);
        return false;
      }
    });
  }, [chats, filterText]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <StylishNewButton onClick={handleAddChat} className="button--primary">
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={filterText}
            onChangeSearchTerm={(val) => setFilterText(val)}
            placeholder="Filter chats by name"
          />
        </div>
      </div>

      <div style={{ flexGrow: 1, overflowY: 'auto' }}>
        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <span>Loading...</span>
          </div>
        ) : filteredChats.length > 0 ? (
          <ListGroup className={'py-0'}>
            <SimpleEntityList
              entities={filteredChats}
              renderEntity={(chat) => (
                <ListGroupItem
                  role="button"
                  tabIndex={0}
                  key={chat.id}
                  className="d-flex justify-content-between align-items-center py-3"
                  onClick={() => handleChatClick(chat)}
                  onKeyDown={(e) => {
                    if (e.target instanceof HTMLButtonElement) {
                      return;
                    }
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleChatClick(chat);
                    }
                  }}
                >
                  <div>
                    <strong title={chat.name}>
                      {chat.name.length > 50
                        ? `${chat.name.slice(0, 50)}...`
                        : chat.name}
                    </strong>
                  </div>
                  <div>
                    <StylishNewButton
                      className="button--secondary button--icon me-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditChat(chat);
                      }}
                    >
                      <SharedIcon iconName={'edit'} size={'sm'} />
                    </StylishNewButton>
                    <StylishNewButton
                      className="button--secondary button--icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteChat(chat);
                      }}
                    >
                      <SharedIcon iconName={'delete'} size={'sm'} />
                    </StylishNewButton>
                  </div>
                </ListGroupItem>
              )}
            />
          </ListGroup>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <span>No chats found.</span>
          </div>
        )}
      </div>
      {chatToEdit && (
        <DisasterChatEditDrawer
          show={!!chatToEdit}
          onHide={() => setChatToEdit(null)}
          chat={chatToEdit}
        />
      )}
      {chatToDelete ? (
        <DeleteModal
          title="Delete Session"
          onClose={() => setChatToDelete(undefined)}
          onConfirmDelete={handleConfirmDelete}
        >
          Delete this DisasterChat session?
        </DeleteModal>
      ) : null}
    </>
  );
};

export default DisasterChatManager;
