import { useEffect, useState } from 'react';
import { sampleDrawLayer } from '../constants/sampleLayers';
import { eriScenarios } from '../constants/eri';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const stateToCountyZoomThreshold = 5;
const eri_source_id = 'eri_source_id';
const eri_source_id_state = 'eri_source_id_state';
const eri_layer_id = 'eri_layer_id';
const eri_layer_id_state = 'eri_layer_id_state';
const eri_source_layer = 'public.eri_layer';
const eri_source_layer_state = 'public.eri_layer_state';

export const useERILayers = (
  map,
  mapHasLoaded,
  apiPrefix,
  mapSettings,
  setLayerClicked,
) => {
  const [previousDatetime, setPreviousDatetime] = useState(mapSettings.selectedDatetime);
  
  const layerExists = (layerId) => !!map.current?.getStyle().layers.find(l => l.id === layerId);
  const sourceExists = (sourceId) => !!map.current?.getSource(sourceId);

  // Helper function to remove existing layers and sources
  const cleanupLayers = () => {
    [eri_layer_id, eri_layer_id_state].forEach(layerId => {
      if (map.current?.getLayer(layerId)) {
        map.current.removeLayer(layerId);
      }
    });

    [eri_source_id, eri_source_id_state].forEach(sourceId => {
      if (map.current?.getSource(sourceId)) {
        map.current.removeSource(sourceId);
      }
    });
  };

  // Helper function to add layers and event handlers
  const addLayer = (config) => {
    map.current.addLayer(config);
    map.current.on('click', config.id, (e) =>
      setLayerClicked({ layer: config, e: e, features: e.features })
    );
    map.current.on('mouseenter', config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });
  };

  // Effect to manage ERI layers
  useEffect(() => {
    if (!mapHasLoaded || !map.current) return;

    const { eriActive, eriScenario, selectedDatetime } = mapSettings;
    const datetimeChanged = !previousDatetime.isSame(selectedDatetime);

    // Update previousDatetime if it changed
    if (datetimeChanged) {
      setPreviousDatetime(selectedDatetime);
    }

    if (!eriActive) {
      // Just hide layers if they exist
      [eri_layer_id, eri_layer_id_state].forEach(layerId => {
        if (layerExists(layerId)) {
          map.current.setLayoutProperty(layerId, 'visibility', 'none');
        }
      });
      return;
    }

    // Show and update existing layers if datetime hasn't changed
    if (layerExists(eri_layer_id) && layerExists(eri_layer_id_state) && !datetimeChanged) {
      [eri_layer_id, eri_layer_id_state].forEach(layerId => {
        map.current.setLayoutProperty(layerId, 'visibility', 'visible');
      });

      // Update colors if scenario changed
      const foundScenario = eriScenarios.find((s) => s.value === eriScenario);
      const newColorExpression = [
        'match',
        ['get', `${eriScenario}`],
        0, '#FFFFFF',
        1, '#ffffb2',
        2, '#fecc5c',
        3, '#fd8d3c',
        4, '#f03b20',
        5, '#bd0026',
        '#FFFFFF',
      ];

      [eri_layer_id, eri_layer_id_state].forEach(layerId => {
        map.current.setPaintProperty(layerId, 'fill-color', newColorExpression);
        map.current.setPaintProperty(layerId, 'fill-outline-color', foundScenario?.color);
      });

      return;
    }

    // Remove existing layers and sources if datetime changed or they need to be created
    cleanupLayers();

    // Create layers with new datetime
    const formattedDatetime = selectedDatetime
      .minute(0)
      .second(0)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    const eriTiles = `${apiPrefix}/model_tiles/${eri_source_layer}/{z}/{x}/{y}.pbf`;
    const eriTilesState = `${apiPrefix}/model_tiles/${eri_source_layer_state}/{z}/{x}/{y}.pbf`;

    // Add sources
    map.current.addSource(eri_source_id, {
      type: 'vector',
      tiles: [`${eriTiles}?datetime_selected=${formattedDatetime}`],
    });

    map.current.addSource(eri_source_id_state, {
      type: 'vector',
      tiles: [`${eriTilesState}?datetime_selected=${formattedDatetime}`],
    });

    // Add layers
    const foundScenario = eriScenarios.find((s) => s.value === eriScenario);
    const eriLayerConfig = {
      ...sampleDrawLayer,
      id: eri_layer_id,
      source: eri_source_id,
      'source-layer': eri_source_layer,
      generateId: true,
      paint: {
        ...sampleDrawLayer.paint,
        'fill-color': [
          'match',
          ['get', `${eriScenario}`],
          0, '#FFFFFF',
          1, '#ffffb2',
          2, '#fecc5c',
          3, '#fd8d3c',
          4, '#f03b20',
          5, '#bd0026',
          '#FFFFFF',
        ],
        'fill-opacity': 0.4,
        'fill-outline-color': foundScenario?.color,
      },
      metadata: {
        eventlayer: false,
        usermade: undefined,
        model_layer: true,
        model_details: {
          model_name: 'Energy Reliability Index',
          model_code: 'ERI',
          political_boundaries_type: 'county',
        },
      },
      minzoom: stateToCountyZoomThreshold,
    };

    addLayer(eriLayerConfig);

    const eriLayerConfigState = {
      ...eriLayerConfig,
      metadata: {
        ...eriLayerConfig.metadata,
        model_details: {
          ...eriLayerConfig.metadata.model_details,
          political_boundaries_type: 'state',
        },
      },
      id: eri_layer_id_state,
      source: eri_source_id_state,
      'source-layer': eri_source_layer_state,
      minzoom: 0,
      maxzoom: stateToCountyZoomThreshold,
    };

    addLayer(eriLayerConfigState);

  }, [mapHasLoaded, mapSettings.eriActive, mapSettings.eriScenario, mapSettings.selectedDatetime]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      cleanupLayers();
    };
  }, []);

  return {
    layerExists,
  };
};