// SharepointFiles.js
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useSharepointFiles } from './hooks/useSharepointFiles'; // Keep the fetch hook
import dayjs from 'dayjs';
import './CustomAccordion.css';

// spinner
const Spinner = () => <i className="fa fa-spinner fa-pulse fs-6"></i>;

const SharepointFiles = ({
  selections = [],
  setSelections,
  hasSharepointLocation,
}) => {
  const { data: files = [], isLoading } = useSharepointFiles(); // Fetch SharePoint files

  // Define handleSelect to manage selection of full row
  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row]); // Add the full row object
    } else {
      setSelections(selections.filter((selection) => selection.id !== row.id)); // Filter by id to remove
    }
  };

  // Define handleSelectAll to manage selection of all rows with full row data
  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelections(rows); // Select all rows
    } else {
      setSelections([]); // Deselect all rows
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: 'File Name',
      sort: true,
      attrs: { title: 'File Name' },
      formatter: (cell, row) => (
        <a
          href={row['@microsoft.graph.downloadUrl']}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#0078d4' }} // Optional styling
        >
          {cell}
        </a>
      ),
    },
    {
      dataField: 'file.mimeType',
      text: 'File Type',
      sort: true,
      attrs: { title: 'File Type' },
    },
    {
      dataField: 'lastModifiedDateTime',
      text: 'Last Modified',
      sort: true,
      attrs: { title: 'Last Modified' },
      formatter: (cell, row) => <>{dayjs(cell).format('YYYY-MM-DD HH:mm')}</>,
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    // clickToSelect: true,
    hideSelectAll: true,
    selected: selections.map((selection) => selection.id), // Only compare by id for checkbox
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  return (
    <div className="SharepointFiles">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="border-b">
            Teams/SharePoint Files
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p style={{ fontSize: '13px' }}>
                Select Files in your Teams SharePoint to optimize DisasterChat.
              </p>
              <p style={{ fontSize: '13px' }}>
                Supported File Types: .csv, .doc, .docx, .md, .msg, .pdf, .pptx,
                .txt, .url, .xls, .xlsx
              </p>
              {(!!hasSharepointLocation && (
                <>
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <StylishNewTable
                      keyField={'id'}
                      rows={files}
                      columns={columns}
                      // selectRow={selectRow}
                    />
                  )}
                </>
              )) || (
                <>
                  <p>
                    <strong>
                      Teams site must be set up at Incident Settings to use
                      SharePoint Files.
                    </strong>
                  </p>
                </>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SharepointFiles;
