import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import {
  IncidentResourceRequirement,
  useIncidentResourceRequirements,
} from './hooks/incidentResourceRequirementHooks';
import IncidentResourceRequirementAddDrawer from './IncidentResourceRequirementAddDrawer';
import IncidentResourceRequirementEditDrawer from './IncidentResourceRequirementEditDrawer';
import { useResourceSets } from './hooks/resourceSetHooks';  // Make sure this hook is implemented to fetch resource sets

import {
  MdEdit,
} from 'react-icons/md';

const CollapsibleIncidentResourceRequirementsList: React.FC = () => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<IncidentResourceRequirement | null>(null);

  const { data: incidentResourceRequirements } = useIncidentResourceRequirements();
  const { data: resourceSets } = useResourceSets(); // Fetch resource sets

  const selectElement = (element: IncidentResourceRequirement) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  const calculateHaveNeed = (kind: string, requiredQuantity: number) => {
    const haveQuantity = (resourceSets||[])
      .filter((resourceSet) => resourceSet.kind === kind)
      .reduce((acc, resourceSet) => acc + resourceSet.quantity, 0);

    const needQuantity = Math.max(requiredQuantity - haveQuantity, 0);

    return { have: haveQuantity, need: needQuantity };
  };

  return (
    <>
      <EntityList
        entities={incidentResourceRequirements}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Incident Resource Requirements </span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => {
          const { have, need } = calculateHaveNeed(element.kind, element.required_quantity);
          return (
            <Card
              key={element.id}
              className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
              style={{
                padding: '0.625rem 1rem 0.625rem 1rem',
              }}
            >
              <span>
                {element.kind}: Req {element.required_quantity} / Have {have} / Need {need}
              </span>
              <StylishNewButton
                className="button button--link"
                onClick={() => selectElement(element)}
              >
                <MdEdit />
              </StylishNewButton>
            </Card>
          );
        }}
      ></EntityList>
      {addOpen ? <IncidentResourceRequirementAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <IncidentResourceRequirementEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleIncidentResourceRequirementsList;
