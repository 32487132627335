import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { useCreateResourceSite } from './hooks/resourceSiteHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormSelectField from './formFields/FormSelectField';
import GeoLocationComponent, { GeoLocation } from 'components/GeoLocationComponent/GeoLocationComponent';
import CustomResourceSiteTypesAddDrawer from './CustomResourceSiteTypesAddDrawer';
import { useCustomResourceTypes } from './hooks/customResourceSiteTypeHooks';
import { resource_site_types } from 'components/ICS/ICSConstants';

type FormFields = {
  name: string;
  description: string;
  geolocation: any;
  archived: boolean;
  type:
    | 'Staging Area'
    | 'Incident Command Post'
    | 'Warehouse'
    | 'Hotel/Lodging';
};

const ResourceSiteAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
  });
  const { handleSubmit } = form;

  const mutation = useCreateResourceSite();
  const [geolocation, setGeolocation] = useState<GeoLocation>();

  const onNewResourceSiteDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      geolocation: geolocation,
      archived: false,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const [showCustomResourceSiteTypeDrawer, setShowCustomResourceSiteTypeDrawer] = useState(false);

  const { data: customResourceSiteTypes = [] } = useCustomResourceTypes();
  
  

  return (
    <DrawerWrapper toggle={toggle} title="Add Resource Site">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewResourceSiteDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'This maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Site Type"
                fieldName="type"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={[
                  ...resource_site_types,
                  ...(customResourceSiteTypes
                    ?.filter((r) => !r.archived)
                    .map((r) => ({ label: r.name, value: r.id })) || [])
                ]}                
                variant="half"
              />
              <span
                  className="text-primary cursor-pointer"
                  onClick={() => setShowCustomResourceSiteTypeDrawer(true)}
                >
                  Add Custom Resource Site Type
              </span>
            </Row>
            <Row>
              <div className="mt-4">
                <label className="form-label">Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
      {showCustomResourceSiteTypeDrawer && <CustomResourceSiteTypesAddDrawer toggle={() => setShowCustomResourceSiteTypeDrawer(false)} />}
    </DrawerWrapper>
  );
};

export default ResourceSiteAddDrawer;
