import React, { useState } from 'react';
import {
  useCreateCustomDataSource,
  useCustomDataSources,
  useDeleteCustomDataSource,
  useUpdateCustomDataSource,
} from './hooks/useCustomDataSources';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import CustomDataSourceEditDrawer from './CustomDataSourceEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon'; // Imported Modal

const CustomDataSourceManager = () => {
  const {
    data: customDataSources = [],
    isLoading,
    isError,
  } = useCustomDataSources();
  const createCustomDataSourceMutation = useCreateCustomDataSource();
  const updateCustomDataSourceMutation = useUpdateCustomDataSource();
  const deleteCustomDataSourceMutation = useDeleteCustomDataSource();

  const [drawerOpen, toggleDrawer] = useToggle(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // New state variables for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedDataSource(null);
    toggleDrawer(true);
  };

  const handleOpenEditDrawer = (dataSource) => {
    setSelectedDataSource(dataSource);
    toggleDrawer(true);
  };

  const handleCloseDrawer = () => {
    setSelectedDataSource(null);
    toggleDrawer(false);
  };

  const handleSaveDataSource = (dataSourceData) => {
    if (selectedDataSource) {
      updateCustomDataSourceMutation.mutate(dataSourceData, {
        onSuccess: () => {
          toast.success(
            'Custom data source updated successfully.',
            toastConfig
          );
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating custom data source:', error);
          toast.error('Failed to update custom data source.', toastConfig);
        },
      });
    } else {
      createCustomDataSourceMutation.mutate(dataSourceData, {
        onSuccess: () => {
          toast.success(
            'Custom data source created successfully.',
            toastConfig
          );
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating custom data source:', error);
          toast.error('Failed to create custom data source.', toastConfig);
        },
      });
    }
  };

  // Modified handleDeleteDataSource to use modal
  const handleDeleteDataSource = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  // New function to confirm deletion
  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteCustomDataSourceMutation.mutate(deleteId, {
        onSuccess: () => {
          toast.success(
            'Custom data source deleted successfully.',
            toastConfig
          );
          setShowDeleteModal(false);
          setDeleteId(null);
        },
        onError: (error) => {
          console.error('Error deleting custom data source:', error);
          toast.error('Failed to delete custom data source.', toastConfig);
          setShowDeleteModal(false);
          setDeleteId(null);
        },
      });
    }
  };

  // New function to cancel deletion
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const filteredDataSources = customDataSources.filter((ds) =>
    ds.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return (
      <div className="error-message">Error loading Custom Data Sources.</div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <StylishNewButton
          onClick={handleOpenAddDrawer}
          className="button--primary"
        >
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(val) => setSearchTerm(val)}
            placeholder="Search Custom Data Sources..."
          />
        </div>
      </div>

      {isLoading ? (
        <div>Loading Custom Data Sources...</div>
      ) : (
        <div
          className="d-flex flex-column flex-grow-1"
          style={{
            overflowY: 'auto',
          }}
        >
          <ListGroup className={'py-0 flex-grow-1'}
            style={{
              overflowY:'scroll'
            }}
          >
            <SimpleEntityList
              entities={filteredDataSources}
              renderEntity={(dataSource) => (
                <ListGroupItem
                  key={dataSource.id}
                  className="d-flex justify-content-between align-items-center py-3"
                >
                  <div>
                    <strong>{dataSource.name}</strong>
                  </div>
                  <div>
                    <StylishNewButton
                      className="button--secondary button--icon me-2"
                      onClick={() => handleOpenEditDrawer(dataSource)}
                    >
                      <SharedIcon iconName={'edit'} size={'sm'} />
                    </StylishNewButton>
                    <StylishNewButton
                      className="button--secondary button--icon"
                      onClick={() => handleDeleteDataSource(dataSource.id)}
                    >
                      <SharedIcon iconName={'delete'} size={'sm'} />
                    </StylishNewButton>
                  </div>
                </ListGroupItem>
              )}
            />
          </ListGroup>
        </div>
      )}

      {drawerOpen && (
        <CustomDataSourceEditDrawer
          show={drawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveDataSource}
          dataSource={selectedDataSource}
        />
      )}

      {/* New Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        centered
        backdrop={true}
        keyboard={true}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Delete Custom Data Source</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Custom Data Source?
        </Modal.Body>
        <Modal.Footer>
          <StylishNewButton
            className="button--secondary"
            onClick={handleCancelDelete}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--tertiary"
            onClick={handleConfirmDelete}
          >
            Delete
          </StylishNewButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomDataSourceManager;
