export const defaultSitreps = [
  {
    sitrep_id: '00000000-0000-0000-0000-000000000000',
    id: '00000000-0000-0000-0000-000000000000',
    sitrep_source: 'MANUAL',
    sitrep_source_id: '00000000-0000-0000-0000-000000000000',
    name: '(Default SITREP)',
    sections: [
      {
        section_id: '00000000-0000-0000-0000-000000000000',
        section_template_id: '00000000-0000-0000-0000-000000000000',
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Current Weather',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000000',
            source: 'Current Weather',
            description:
              'LOCATION REQUIRED (Event).  Current weather at location.',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Paragraph', 'Table'],
        },
        data_type_source: 'ai',
        order: 1,
        section_versions: [],
      },
      {
        section_id: '00000000-0000-0000-0000-000000000001',
        section_template_id: '00000000-0000-0000-0000-000000000001',
        id: '00000000-0000-0000-0000-000000000001',
        name: 'Forecast Weather',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000001',
            source: 'Forecast Weather',
            description:
              'LOCATION REQUIRED (Event).  Forecast weather at location.',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Table'],
        },
        data_type_source: 'ai',
        order: 2,
        section_versions: [],
      },
      {
        section_id: '00000000-0000-0000-0000-000000000002',
        section_template_id: '00000000-0000-0000-0000-000000000002',
        id: '00000000-0000-0000-0000-000000000002',
        name: 'News',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000002',
            source: 'News',
            description: 'News Articles',
          },
          {
            id: '00000000-0000-0000-0000-000000000003',
            source: 'Web',
            description: 'Web Scraping',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Paragraph', 'List'],
        },
        data_type_source: 'ai',
        order: 3,
        section_versions: [],
      },
      {
        section_id: '00000000-0000-0000-0000-000000000003',
        section_template_id: '00000000-0000-0000-0000-000000000003',
        id: '00000000-0000-0000-0000-000000000003',
        name: 'Social Media',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000004',
            source: 'Social Media',
            description: 'Social Media Posts',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Paragraph'],
        },
        data_type_source: 'ai',
        order: 4,
        section_versions: [],
      },
      {
        section_id: '00000000-0000-0000-0000-000000000004',
        section_template_id: '00000000-0000-0000-0000-000000000004',
        id: '00000000-0000-0000-0000-000000000004',
        name: 'Power Outages',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000005',
            source: 'Power Outages',
            description:
              'LOCATION REQUIRED (Event).  Power outages at location.',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Paragraph', 'Table'],
        },
        data_type_source: 'ai',
        order: 5,
        section_versions: [],
      },
      {
        section_id: '00000000-0000-0000-0000-000000000005',
        section_template_id: '00000000-0000-0000-0000-000000000005',
        id: '00000000-0000-0000-0000-000000000005',
        name: 'Energy Reliability Index',
        type: 'ai',
        data_sources: [
          {
            id: '00000000-0000-0000-0000-000000000006',
            source: 'ERI (Energy Reliability Index)',
            description:
              'LOCATION REQUIRED (Event).  ERI (Energy Reliability Index) at location.',
          },
        ],
        ai_instructions: '',
        output_format: '',
        output_format_structured: {
          summary_options: ['Paragraph', 'Table'],
        },
        data_type_source: 'ai',
        order: 6,
        section_versions: [],
      },
    ],
  },
];

export const defaultSectionTemplates = [
  {
    section_id: '00000000-0000-0000-0000-000000000000',
    section_template_id: '00000000-0000-0000-0000-000000000000',
    id: '00000000-0000-0000-0000-000000000000',
    name: 'Current Weather',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000000',
        source: 'Current Weather',
        description: 'LOCATION REQUIRED (Event).  Current weather at location.',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Paragraph', 'Table'],
    },
    data_type_source: 'ai',
    order: 1,
    section_versions: [],
  },
  {
    section_id: '00000000-0000-0000-0000-000000000001',
    section_template_id: '00000000-0000-0000-0000-000000000001',
    id: '00000000-0000-0000-0000-000000000001',
    name: 'Forecast Weather',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000001',
        source: 'Forecast Weather',
        description:
          'LOCATION REQUIRED (Event).  Forecast weather at location.',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Table'],
    },
    data_type_source: 'ai',
    order: 2,
    section_versions: [],
  },
  {
    section_id: '00000000-0000-0000-0000-000000000002',
    section_template_id: '00000000-0000-0000-0000-000000000002',
    id: '00000000-0000-0000-0000-000000000002',
    name: 'News',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000002',
        source: 'News',
        description: 'News Articles',
      },
      {
        id: '00000000-0000-0000-0000-000000000003',
        source: 'Web',
        description: 'Web Scraping',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Paragraph', 'List'],
    },
    data_type_source: 'ai',
    order: 3,
    section_versions: [],
  },
  {
    section_id: '00000000-0000-0000-0000-000000000003',
    section_template_id: '00000000-0000-0000-0000-000000000003',
    id: '00000000-0000-0000-0000-000000000003',
    name: 'Social Media',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000004',
        source: 'Social Media',
        description: 'Social Media Posts',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Paragraph'],
    },
    data_type_source: 'ai',
    order: 4,
    section_versions: [],
  },
  {
    section_id: '00000000-0000-0000-0000-000000000004',
    section_template_id: '00000000-0000-0000-0000-000000000004',
    id: '00000000-0000-0000-0000-000000000004',
    name: 'Power Outages',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000005',
        source: 'Power Outages',
        description: 'LOCATION REQUIRED (Event).  Power outages at location.',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Paragraph', 'Table'],
    },
    data_type_source: 'ai',
    order: 5,
    section_versions: [],
  },
  {
    section_id: '00000000-0000-0000-0000-000000000005',
    section_template_id: '00000000-0000-0000-0000-000000000005',
    id: '00000000-0000-0000-0000-000000000005',
    name: 'Energy Reliability Index',
    type: 'ai',
    data_sources: [
      {
        id: '00000000-0000-0000-0000-000000000006',
        source: 'ERI (Energy Reliability Index)',
        description:
          'LOCATION REQUIRED (Event).  ERI (Energy Reliability Index) at location.',
      },
    ],
    ai_instructions: '',
    output_format: '',
    output_format_structured: {
      summary_options: ['Paragraph', 'Table'],
    },
    data_type_source: 'ai',
    order: 6,
    section_versions: [],
  },
];
