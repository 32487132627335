import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React from 'react';
import { Modal } from 'react-bootstrap';

type DeleteModalProps = React.PropsWithChildren & {
  title: string;
  onClose: () => void;
  onConfirmDelete: () => void;
  buttonDisable?: boolean;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  title,
  onClose,
  onConfirmDelete,
  buttonDisable,
  children,
}) => {
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <StylishNewButton
          type="button"
          className="button--secondary"
          onClick={onClose}
        >
          Cancel
        </StylishNewButton>
        <StylishNewButton
          className="button--tertiary"
          onClick={onConfirmDelete}
          disabled={!!buttonDisable}
        >
          Delete
        </StylishNewButton>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteModal;
