import React, { useState } from 'react';
import {
  useCreateSectionTemplate,
  useDeleteSectionTemplate,
  useSectionTemplates,
  useUpdateSectionTemplate,
} from './hooks/useSectionTemplates';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SimpleEntityList from 'components/DesignSystems/New/SimpleEntityList';
import { useToggle } from 'react-use';
import SectionTemplateEditDrawer from './SectionTemplateEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import DeleteModal from '../DesignSystems/DeleteModal'; // Imported Modal
import { defaultSectionTemplates } from './SitrepDefaults';

const SectionTemplateManager = () => {
  const {
    data: sectionTemplates = [],
    isLoading,
    isError,
  } = useSectionTemplates();
  const createSectionTemplateMutation = useCreateSectionTemplate();
  const updateSectionTemplateMutation = useUpdateSectionTemplate();
  const deleteSectionTemplateMutation = useDeleteSectionTemplate();

  const [addDrawerOpen, toggleAddDrawer] = useToggle(false);
  const [editDrawerOpen, toggleEditDrawer] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [deleteId, setDeleteId] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer();
  };

  const handleOpenEditDrawer = (template) => {
    setSelectedTemplate(template);
    toggleEditDrawer();
  };

  const handleCloseDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer(false);
    toggleEditDrawer(false);
  };

  const handleSaveTemplate = (templateData) => {
    if (selectedTemplate) {
      updateSectionTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('Section template updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating section template:', error);
          toast.error('Failed to update section template.', toastConfig);
        },
      });
    } else {
      createSectionTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('Section template created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating section template:', error);
          toast.error('Failed to create section template.', toastConfig);
        },
      });
    }
  };

  // Modified handleDeleteTemplate to use modal
  const handleDeleteTemplate = (id) => {
    setDeleteId(id);
  };

  // New function to confirm deletion
  const handleConfirmDelete = () => {
    if (deleteId) {
      deleteSectionTemplateMutation.mutate(deleteId, {
        onSuccess: () => {
          toast.success('Section template deleted successfully.', toastConfig);
          setDeleteId(null);
        },
        onError: (error) => {
          console.error('Error deleting section template:', error);
          toast.error('Failed to delete section template.', toastConfig);
          setDeleteId(null);
        },
      });
    }
  };

  // New function to cancel deletion
  const handleCancelDelete = () => {
    setDeleteId(null);
  };

  const filteredTemplates = [
    ...defaultSectionTemplates,
    ...sectionTemplates,
  ].filter((template) =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return (
      <div className="error-message">Error loading section templates.</div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <StylishNewButton
          onClick={handleOpenAddDrawer}
          className="button--primary"
        >
          Add
        </StylishNewButton>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(val) => setSearchTerm(val)}
            placeholder="Search Section Templates..."
          />
        </div>
      </div>

      {isLoading ? (
        <div>Loading Section Templates...</div>
      ) : (
        <div
          className="d-flex flex-column flex-grow-1"
          style={{
            overflowY: 'auto',
          }}
        >
          <ListGroup className={'py-0 flex-grow-1'}  
            style={{
              overflowY:'scroll'
            }}
          >
            <SimpleEntityList
              entities={filteredTemplates}
              renderEntity={(template) => (
                <ListGroupItem
                  key={template.id}
                  className="d-flex justify-content-between align-items-center py-3"
                >
                  <div>
                    <strong>{template.name}</strong> ({template.type})
                  </div>
                  {defaultSectionTemplates.find((t) => t.id === template.id) ? (
                    <span>(Default template)</span>
                  ) : (
                    <div>
                      <StylishNewButton
                        className="button--secondary button--icon me-2"
                        onClick={() => handleOpenEditDrawer(template)}
                      >
                        <SharedIcon iconName={'edit'} size={'sm'} />
                      </StylishNewButton>
                      <StylishNewButton
                        className="button--secondary button--icon"
                        onClick={() => handleDeleteTemplate(template.id)}
                      >
                        <SharedIcon iconName={'delete'} size={'sm'} />
                      </StylishNewButton>
                    </div>
                  )}
                </ListGroupItem>
              )}
            />
          </ListGroup>
        </div>
      )}

      {(addDrawerOpen || editDrawerOpen) && (
        <SectionTemplateEditDrawer
          show={addDrawerOpen || editDrawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveTemplate}
          template={selectedTemplate}
        />
      )}

      {deleteId ? (
        <DeleteModal
          title="Delete Section Template"
          onClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
        >
          Are you sure you want to delete this Section Template?
        </DeleteModal>
      ) : null}
    </>
  );
};

export default SectionTemplateManager;
